import { useMemo } from 'react';

import { useFlagsContext } from '../flagsContext';
import { MenuItemsProviderProperties } from './menuItemContext.types';
import { MenuItemsContext } from './menuItemsContext';
import { commonMenuItems } from './menuItemsDefaults';

interface MainMenuNavigationLink {
    text: string;
    leftIconName: string;
    href?: string;
    caseSensitive?: boolean;
}

export function MenuItemsProvider({
    children,
    menuItems,
}: MenuItemsProviderProperties) {
    const {
        flags: {
            support_centre_enabled: { enabled: enabledSupportCentre },
        },
    } = useFlagsContext();
    const contextValue = useMemo(() => {
        const menu = { ...commonMenuItems, ...menuItems };
        const mainMenuWithoutSC = menu.mainMenu.filter(
            ({ text }: MainMenuNavigationLink) => text !== 'help & support',
        );
        const flaggedMenuItems = enabledSupportCentre
            ? menu
            : {
                  ...menu,
                  mainMenu: mainMenuWithoutSC,
              };
        return { menuItems: flaggedMenuItems };
    }, [menuItems, enabledSupportCentre]);
    return (
        <MenuItemsContext.Provider value={contextValue}>
            {children}
        </MenuItemsContext.Provider>
    );
}
