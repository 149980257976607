import { format } from 'date-fns';

import {
    EventsMap,
    EventKey,
    AccordionClickPayload,
    AnalyticsErrorEvent,
    GymPageJoinNowPayload,
    UserInformation,
} from './analytics.types';
import { encodeAsSHA256 } from '@tgg/util';

export const accordionClick = (payload: string) => {
    const { accordionContent }: AccordionClickPayload = JSON.parse(payload);

    return {
        event: EventKey.ACCORDION_CLICK,
        accordionContent: accordionContent.slice(0, 29),
    };
};

export const errorTracking = (payload: string) => {
    const { category, details }: AnalyticsErrorEvent = JSON.parse(payload);
    return {
        event: EventKey.ERROR,
        errorCategory: category,
        errorDetail: `${details.title} - ${details.description}`,
    };
};

export const gymPageJoinNow = (payload: string) => {
    const gymPagePayload: Partial<GymPageJoinNowPayload> = JSON.parse(payload);

    const baseObject = {
        ctaPosition: '',
        membershipType: '',
        membershipProduct: '',
        joinNowGym: '',
        joiningFee: '',
        monthlyFee: '',
        oneOffFee: '',
        promoJoiningFee: '',
        promoMonthlyFee: '',
        promoOneOffFee: '',
        promo: '',
        kickerPriceMonthlyFee: '',
        kickerPriceDuration: '',
    };

    return {
        event: EventKey.GYM_PAGE_JOIN_NOW,
        ...baseObject,
        ...gymPagePayload,
    };
};

export const encodeUserInfo = ({
    postcode = '',
    cityTown = '',
    phoneNumber = '',
    email = '',
    firstName = '',
    lastName = '',
    maleFemale = '',
    dob = '',
} = {}): UserInformation => {
    const firstNameTransformed = firstName
        .toLowerCase()
        .replace(/[\p{P}$+<=>^`|~\s]/gu, '');
    const lastNameTransformed = lastName
        .toLowerCase()
        .replace(/[\p{P}$+<=>^`|~\s]/gu, '');
    const emailTransformed = email.trim().toLowerCase();
    const phoneNumberTransformed = phoneNumber.replace('+', '');
    const cityTownTransformed = cityTown.replace(/[^A-z]/g, '').toLowerCase();
    const postcodeTransformed = postcode.replace(' ', '').toLowerCase();
    const dobTransformed = dob ? format(new Date(dob), 'yyyyMMdd') : '';
    const genderTransformed = { Female: 'f', Male: 'm' }[maleFemale] || '';

    return {
        fn: encodeAsSHA256(firstNameTransformed),
        ln: encodeAsSHA256(lastNameTransformed),
        em: encodeAsSHA256(emailTransformed),
        ph: encodeAsSHA256(phoneNumberTransformed),
        ph_plus: encodeAsSHA256(phoneNumber),
        ct: encodeAsSHA256(cityTownTransformed),
        zp: encodeAsSHA256(postcodeTransformed),
        db: encodeAsSHA256(dobTransformed),
        ge: encodeAsSHA256(genderTransformed),
    };
};

export const customEventHandlerMap: EventsMap = {
    [EventKey.ACCORDION_CLICK]: accordionClick,
    [EventKey.ERROR]: errorTracking,
    [EventKey.GYM_PAGE_JOIN_NOW]: gymPageJoinNow,
};
