import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

import { useBackToTopContext } from '../../contexts';
import { palette } from '../../theme';
import { ButtonBase } from '../Button';
import { Icon } from '../Icon';
import { StyledFloatingButton } from './BackToTopButton.styled';
import { dispatchEvent, EventKey, CtaButtonClick } from '@tgg/services';

const scrolledPixelsToShowButton = 300;
const headerHeight = 60;
const throttleMs = 500;
const buttonText = 'back to top';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    dispatchEvent<CtaButtonClick>(EventKey.CTA_BUTTON, {
        cta_name: buttonText,
        cta_position: 'floating',
        page_name: window.document.title,
    });
};

/**
 * BackToTopButton component is to show a floating button that scrolls to top of the page
 */
export function BackToTopButton() {
    const [isVisible, setVisible] = useState(false);

    const { elementReference, additionalOffset } = useBackToTopContext();

    const actualOffsetPx =
        elementReference?.current &&
        elementReference.current.offsetTop > scrolledPixelsToShowButton
            ? elementReference.current.offsetTop -
              headerHeight -
              additionalOffset
            : scrolledPixelsToShowButton;

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        setVisible(scrolled > actualOffsetPx);
    };

    const throttledToggleVisible = throttle(toggleVisible, throttleMs);

    useEffect(() => {
        window.addEventListener('scroll', throttledToggleVisible);

        return () => {
            window.removeEventListener('scroll', throttledToggleVisible);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementReference?.current?.offsetTop, additionalOffset]);

    return (
        <StyledFloatingButton $isVisible={isVisible}>
            <ButtonBase
                fullWidth={false}
                text={buttonText}
                onClick={scrollToTop}
                buttonStyle="primary-navy"
                startIcon={<Icon name="arrowUp" color={palette.common.white} />}
            />
        </StyledFloatingButton>
    );
}

export default BackToTopButton;
