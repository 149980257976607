/* istanbul ignore file */

import { GlobalStyles } from '@mui/material';

const getFontFaces = (properties: { baseUrl?: string }) => {
    const baseUrl = properties.baseUrl ? `${properties.baseUrl}/` : '/';
    const location = `${baseUrl}fonts`;

    return [
        {
            fontWeight: 'bold',
            fontFamily: 'thegymgroupsans-Bold',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            unicodeRange: 'U+000-5FF',
            src: `local('thegymgroupsans-Bold'), url('${location}/thegymgroupsans-Bold.woff2') format('woff2'), url('${location}/thegymgroupsans-Bold.woff') format('woff');`,
        },
        {
            fontWeight: '500',
            fontFamily: 'thegymgroupsans-BoldItalic',
            fontStyle: 'italic',
            fontDisplay: 'swap',
            unicodeRange: 'U+000-5FF',
            src: `local('thegymgroupsans-BoldItalic'), url('${location}/thegymgroupsans-BoldItalic.woff2') format('woff2'), url('${location}/thegymgroupsans-BoldItalic.woff') format('woff');`,
        },
        {
            fontWeight: '500',
            fontFamily: 'thegymgroupsans-Headline',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            unicodeRange: 'U+000-5FF',
            src: `local('thegymgroupsans-Headline'), url('${location}/thegymgroupsans-Headline.woff2') format('woff2'), url('${location}/thegymgroupsans-Headline.woff') format('woff');`,
        },
    ];
};

// eslint-disable-next-line unicorn/prevent-abbreviations
export const Fonts: React.FC<{ baseUrl?: string }> = props => {
    const fontFaces = getFontFaces(props);

    return (
        <GlobalStyles
            styles={{
                '@font-face': fontFaces,
            }}
        />
    );
};
