/* istanbul ignore file */
import { createContext } from 'react';

import { SnackBarContextProperties } from './snackBarContext.types';

export const SnackBarContext = createContext<SnackBarContextProperties>([
    {
        content: '',
        barType: 'none',
        iconName: 'announcements',
    },
    () => {},
]);
