import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Palette } from '../../theme';

export interface StyledHorizontalRuleProperties {
    variant: string;
    color: Palette;
}

export const StyledHorizontalRule = styled(
    Divider,
)<StyledHorizontalRuleProperties>`
    width: ${({ variant }) => (variant === 'inset' ? '100%' : 'initial')};
    background-color: ${({ color }) => color};
    opacity: 0.5;
`;
