import CssBaseline from '@mui/material/CssBaseline';
import {
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import App, { AppContext } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

import RootErrorBoundary from '../components/RootErrorBoundary/RootErrorBoundary';
import { withApplicationInsights } from '../config';
import { applicationIdentifierShort } from '../core/constants';
import { LocalConfig } from '../next.config.types';
import { AppPropertiesWithLayout } from './_app.types';
import { OptimizelyFeatureFlags } from '@tgg/common-types';
import {
    getCanonicalURL,
    getRobotsMetaData,
    urlSlugToPageTitle,
} from '@tgg/seo';
import { initiateAnalytics, useTrackVirtualPageView } from '@tgg/services';
import {
    Global,
    MenuItemsProvider,
    commonMenuItems,
    kioskNavigationItems,
    theme,
    Fonts,
    SnackBarProvider,
    FlagsProvider,
    PromoCodeProvider,
    AppConfigProvider,
} from '@tgg/ui';
import { getAllFeatureFlagsSsr, getFeatureFlagSsr } from '@tgg/util';
import { appLogger } from 'apps/commerce/core/appLogger';

const {
    publicRuntimeConfig,
    serverRuntimeConfig: { OPTIMIZELY_SDK_KEY },
}: LocalConfig = getConfig();

if (
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    !window.location.href.includes('visualisations')
) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const ReactDOM = require('react-dom');
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const axe = require('@axe-core/react');

    axe(React, ReactDOM, 1000);
}

// Remove #! if its present in the url
if (typeof window !== 'undefined' && window.location.hash === '#!') {
    window.history.replaceState(
        null,
        '',
        window.location.pathname + window.location.search,
    );
}

// Check if we're on /maintenance to determine global styles.
let isMaintenancePage = false;

if (
    typeof window !== 'undefined' &&
    window.location.href.includes('/maintenance')
) {
    isMaintenancePage = true;
}

function CustomApp({
    Component,
    pageProps,
    menuItemsProp,
    flagsContextData,
}: AppPropertiesWithLayout) {
    const DEFAULT_DESCRIPTION =
        'The Gym Group offers an affordable and convenient way for you to exercise across the UK with cheap contract-free memberships.';
    const router = useRouter();
    const { asPath } = router;

    const title: string =
        pageProps.schema?.seoTitle || urlSlugToPageTitle(asPath);
    const description: string =
        pageProps.schema?.seoDescription || DEFAULT_DESCRIPTION;
    const seoRobotsMetaData: string = pageProps.schema?.robotsMetaData || '';
    const seoCanonical: string = pageProps.schema?.seoCanonical || '';
    const publicAssetBasePath = `${publicRuntimeConfig.APP_BASE_PATH}/ywnqh`;
    const publicBaseUrl = publicRuntimeConfig.APP_BASE_URL;

    // After the first run we set this to false
    const firstRun = useRef(true);
    // This only runs once on SRR and once on hydration, good place to run init codes
    if (firstRun.current) {
        initiateAnalytics();

        // Set the ref to false until the next server side load
        firstRun.current = false;
    }

    useTrackVirtualPageView();

    const getLayout = Component.getLayout ?? (page => page);

    const canonicalURL = getCanonicalURL(seoCanonical, publicBaseUrl, asPath);
    const robotsMetaData = getRobotsMetaData(seoRobotsMetaData);

    return (
        <>
            <Head>
                <title>{title}</title>
                <link
                    rel="icon"
                    href={`${publicRuntimeConfig.APP_BASE_PATH}/favicon.ico`}
                />
                <link rel="canonical" href={canonicalURL} />
                <meta name="robots" content={robotsMetaData} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta name="keywords" />
                <meta name="description" content={description} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:image" content={publicBaseUrl} />
                <meta property="twitter:description" content={description} />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                />
                <meta
                    name="google-site-verification"
                    content="2go5Kl_PGeZR6fvq5bwt278nPsWvE_rzwQV92NcBll0"
                />
            </Head>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Global
                        $isMaintenancePage={isMaintenancePage}
                        theme={theme}
                    />
                    <Fonts baseUrl={publicAssetBasePath} />
                    <SnackBarProvider>
                        <RootErrorBoundary>
                            <PromoCodeProvider>
                                <AppConfigProvider
                                    appConfig={{
                                        appIdentifier:
                                            applicationIdentifierShort,
                                    }}
                                >
                                    <FlagsProvider
                                        flagsContextData={flagsContextData}
                                    >
                                        <MenuItemsProvider
                                            menuItems={menuItemsProp}
                                        >
                                            {getLayout(
                                                <Component {...pageProps} />,
                                            )}
                                        </MenuItemsProvider>
                                    </FlagsProvider>
                                </AppConfigProvider>
                            </PromoCodeProvider>
                        </RootErrorBoundary>
                    </SnackBarProvider>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </>
    );
}

export const appInsightsConfig = {
    instrumentationKey: publicRuntimeConfig.APP_INSIGHTS_KEY,
    isEnabled: publicRuntimeConfig.APP_INSIGHTS_ENABLED,
    cookieCfg: {
        enabled: !!(
            (
                typeof window !== 'undefined' &&
                window.OnetrustActiveGroups &&
                window.OnetrustActiveGroups?.includes('C0002')
            ) // Performance cookies (I.E Tracking) are type C0002
        ),
    },
    loggingLevelTelemetry:
        publicRuntimeConfig.APP_INSIGHTS_CLIENT_SDK_LOGGING_LEVEL_TELEMETRY,
    loggingLevelConsole:
        publicRuntimeConfig.APP_INSIGHTS_CLIENT_SDK_LOGGING_LEVEL_CONSOLE,
};

const CustomAppWithApplicationInsights =
    withApplicationInsights(appInsightsConfig)(CustomApp);
/**
For the initial page load, getInitialProps will run on the server only.
getInitialProps will then run on the client when navigating to a different route via the next/link component or by using next/router.
However, if getInitialProps is used in a custom _app.js, and the page being navigated to implements getServerSideProps, then getInitialProps will run on the server.
 */
// @ts-ignore
CustomAppWithApplicationInsights.getInitialProps = async (
    appContext: AppContext,
) => {
    const sitPatTest = appContext.router.query.KIOSK_TEST === '';
    const menuItems =
        publicRuntimeConfig.APP_BASE_URL.includes('kiosk') || sitPatTest
            ? kioskNavigationItems
            : commonMenuItems;

    const appProperties = await App.getInitialProps(appContext);

    const result = await getFeatureFlagSsr(
        appContext.ctx,
        OptimizelyFeatureFlags.USE_OPTIMIZELY_REACT_SDK,
        { logger: appLogger },
    );

    const {
        flag: {
            enabled: useOptimizelyClient,
            variationKey: optimizelyClientImplementation,
        },
        userId: flagsUserId,
        pathname,
    } = result;

    const useOptimizelyReactSdk = optimizelyClientImplementation === 'client';
    const useOptimizelyProxy = optimizelyClientImplementation === 'proxy';

    const { flags } = useOptimizelyClient
        ? { flags: null }
        : await getAllFeatureFlagsSsr(appContext.ctx, {
              logger: appLogger,
          });

    return {
        ...appProperties,
        menuItemsProp: menuItems,
        flagsContextData: {
            flags,
            pathname,
            flagsUserId,
            sdkKey: OPTIMIZELY_SDK_KEY,
            useOptimizelyReactSdk,
            useOptimizelyProxy,
        },
    } as AppPropertiesWithLayout;
};

export default CustomAppWithApplicationInsights;
