/* eslint-disable @typescript-eslint/no-floating-promises */
import { ReactNode, useMemo, useState } from 'react';

import { FlagsContext, FlagsContextProperties } from './flagsContext';
import { useOptimizelyBeClient } from './hooks/useOptimizelyBeClient';
import {
    OptimizelyClientHookConfig,
    useOptimizelyFeClient,
} from './hooks/useOptimizelyFeClient';
import { useOptimizelyProxyClient } from './hooks/useOptimizelyProxyClient';
import { useUserIdentification } from './hooks/useUserIdentification';
import { Nullable } from '@tgg/common-types';
import { TggFlagsObject, defaultFlags } from '@tgg/util';

export type FlagsProviderProperties = {
    children: ReactNode;
    flagsContextData: {
        flags: Nullable<TggFlagsObject>;
        flagsUserId: string;
        pathname: string;
        sdkKey: string;
        useOptimizelyReactSdk: boolean;
        useOptimizelyProxy: boolean;
    };
};

export function FlagsProvider({
    children,
    flagsContextData,
}: FlagsProviderProperties) {
    const {
        flags,
        flagsUserId,
        pathname,
        sdkKey,
        useOptimizelyReactSdk,
        useOptimizelyProxy,
    } = flagsContextData ?? /* istanbul ignore next */ {};
    const [isClientError, setIsClientError] = useState(false);
    const [render, setRender] = useState(false);
    const [resolvedFlags, setResolvedFlags] = useState({
        ...defaultFlags,
        ...flags,
    });

    const userConfig = useMemo(
        () => ({
            flagsUserId,
            pathname,
            useOptimizelyReactSdk,
            useOptimizelyProxy,
        }),
        [flagsUserId, pathname, useOptimizelyReactSdk, useOptimizelyProxy],
    );

    const { isUserInitialized, resolvedUserId } =
        useUserIdentification(userConfig);

    const baseClientConfig = useMemo(
        () =>
            ({
                pathname,
                useOptimizelyReactSdk,
                isUserInitialized,
                resolvedUserId,
                sdkKey,
                isRendered: render,
                setFlags: setResolvedFlags,
                setIsClientError,
                setRender,
            } as Omit<OptimizelyClientHookConfig, 'useClient'>),
        [
            pathname,
            useOptimizelyReactSdk,
            isUserInitialized,
            sdkKey,
            resolvedUserId,
            render,
        ],
    );

    const { trackFlagsEvent: trackFlagsEventByFeClient } =
        useOptimizelyFeClient({
            ...baseClientConfig,
            useClient: useOptimizelyReactSdk,
        });

    const { trackFlagsEvent: trackFlagsEventByBeClient } =
        useOptimizelyBeClient({
            ...baseClientConfig,
            useClient: !useOptimizelyReactSdk && !useOptimizelyProxy,
            resolvedFlags,
        });

    const { trackFlagsEvent: trackFlagsEventByProxyClient } =
        useOptimizelyProxyClient({
            ...baseClientConfig,
            useClient: useOptimizelyProxy,
        });

    const trackFlagsEvent = useOptimizelyReactSdk
        ? trackFlagsEventByFeClient
        : useOptimizelyProxy
        ? trackFlagsEventByProxyClient
        : trackFlagsEventByBeClient;

    const contextValue: FlagsContextProperties = useMemo(() => {
        return {
            flags: resolvedFlags,
            flagsUserId: resolvedUserId,
            pathname,
            trackFlagsEvent,
        };
    }, [resolvedFlags, resolvedUserId, pathname, trackFlagsEvent]);

    return (
        <FlagsContext.Provider value={contextValue}>
            {render && children}
        </FlagsContext.Provider>
    );
}
