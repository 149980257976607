import { AxiosInstance, AxiosResponse } from 'axios';

import {
    CapitalizedProperties,
    UnwrapUnCapitalizedObjct,
    capitalizeObjectProperties,
    isObject,
    unCapitalizeObjectProperties,
} from '../objectTransform';
import {
    ApiMethodType,
    ApiServiceCallerDataType,
    BaseServiceInput,
    ApiService,
    ApiServiceCallerData,
    ProxyServiceRawResponseBody,
    ProxyServiceResponseBody,
} from './apiServices.types';
import { axiosInstance } from '@tgg/micro-services/axios';

/**
 * common logic for config/headers
 */
export const resolveCallInputs = <T extends ApiMethodType>(
    callerInput: ApiServiceCallerDataType<T, any>,
    options: BaseServiceInput<any, any, any>,
) => {
    const { url: baseUrl = '', subscriptionKey } = callerInput.apiConfig;
    const url = `${baseUrl}${options.path}`;
    const apiVersion = callerInput.apiVersion ?? options.apiVersion;
    const headers: Record<string, any> = {
        ...options.config?.headers,
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        version: apiVersion ?? 'v1.0',
    };
    if (callerInput.accessToken) {
        headers.Authorization = `Bearer ${callerInput.accessToken}`;
    }
    return { url, headers };
};

/**
 * T - type - get, post, put...
 * R - return type
 * D - data type for requests with body
 * O - transform output result type
 */
export const createApiService = <T extends ApiMethodType, R, D = any, O = R>(
    type: T,
    options: BaseServiceInput<R, O, D>,
): ApiService<T, R, D, O> => {
    /**
     * get proper method from axios
     */
    const caller = axiosInstance[type];

    if (type === 'get') {
        /**
         * custom caller used for testing
         */
        return async (callerInput, customCaller?: AxiosInstance['get']) => {
            const { url, headers } = resolveCallInputs(callerInput, options);

            /**
             * get data
             */
            const response = await (
                (customCaller as AxiosInstance['get']) ??
                /* istanbul ignore next */ (caller as AxiosInstance['get'])
            )<T, AxiosResponse<ProxyServiceRawResponseBody<R>>>(url, {
                ...options.config,
                headers,
            });

            const isResponseAnObject = isObject(response.data);

            /**
             * uncapitalize response if object
             */
            const preTransformedResponse = {
                ...response,
                data: isResponseAnObject
                    ? { ...unCapitalizeObjectProperties(response.data) }
                    : { data: response.data },
            };

            /**
             * transform response if transformFunction defined
             */
            const transformedResponse =
                typeof options.responseTransformer === 'function'
                    ? {
                          ...preTransformedResponse,
                          data: {
                              ...preTransformedResponse.data,
                              data: options.responseTransformer(
                                  preTransformedResponse.data
                                      .data as UnwrapUnCapitalizedObjct<R>,
                              ),
                          },
                      }
                    : preTransformedResponse;
            return transformedResponse as AxiosResponse<
                ProxyServiceResponseBody<O>
            >;
        };
    }

    return async (callerInput, customCaller?: AxiosInstance[T]) => {
        const { url, headers } = resolveCallInputs(callerInput, options);
        const inputData = (callerInput as ApiServiceCallerData<D>).data;
        const dataToSend =
            isObject(inputData) && !options.unCapitalizeProperties
                ? (capitalizeObjectProperties(
                      inputData as Record<string, any>,
                  ) as CapitalizedProperties<D>)
                : inputData;

        const response = await (
            (customCaller as AxiosInstance['post']) ??
            /* istanbul ignore next */ (caller as AxiosInstance['post'])
        )<
            T,
            AxiosResponse<ProxyServiceRawResponseBody<R>>,
            CapitalizedProperties<D> | D
        >(url, dataToSend, {
            ...options.config,
            headers,
        });

        const isResponseAnObject = isObject(response.data);

        const preTransformedResponse = {
            ...response,
            data: isResponseAnObject
                ? { ...unCapitalizeObjectProperties(response.data) }
                : { data: response.data },
        };

        const transformedResponse =
            typeof options.responseTransformer === 'function'
                ? {
                      ...preTransformedResponse,
                      data: {
                          ...preTransformedResponse.data,
                          data: options.responseTransformer(
                              preTransformedResponse.data
                                  .data as UnwrapUnCapitalizedObjct<R>,
                          ),
                      },
                  }
                : preTransformedResponse;
        return transformedResponse as AxiosResponse<
            ProxyServiceResponseBody<O>
        >;
    };
};
