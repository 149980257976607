/* istanbul ignore file */

import { GlobalStyles } from '@mui/material';

import { palette } from './theme';
import { Theme } from './theme.types';

interface StyledGlobalProperties {
    $isMaintenancePage?: boolean;
    theme: Theme;
}

const getGlobalStyles = ({
    $isMaintenancePage,
    theme,
}: StyledGlobalProperties) => ({
    html: {
        fontSize: '62.5%',
        height: $isMaintenancePage ? '100vh' : '',
        scrollPaddingTop: '8rem',
    },
    body: {
        fontWeight: 'normal',
        backgroundColor: palette.common.white,
        height: $isMaintenancePage ? '100vh' : '',
    },
    button: {
        fontFamily: 'inherit',
    },
    'div#__next, div#__next > div': {
        height: $isMaintenancePage ? '100vh' : '',
    },
    p: {
        strong: {
            fontFamily: 'thegymgroupsans-Headline',
        },
        em: {
            fontFamily: 'thegymgroupsans-BoldItalic',
        },
    },
    '.pca .pcaautocomplete': {
        width: '31.5rem',
        marginLeft: '-2.6rem',
        border: 'none',
        borderRadius: '2.5rem',
        fontSize: '14px',
        [theme.breakpoints.up('desktop')]: {
            width: '41rem',
        },
        '.pcalist': {
            borderStyle: 'solid',
            borderWidth: '0.1rem',
            borderRadius: '2.5rem',
            borderColor: 'inherit',
        },
        '.pcaitem': {
            borderBottom: '1px solid lightgrey',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '1.3rem 1.5rem',
            '&.pcalastitem': {
                border: 'none',
            },
        },
    },
    '.visually-hidden': {
        position: 'absolute',
        width: '0.1rem',
        height: '0.1rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
    },
});

// eslint-disable-next-line unicorn/prevent-abbreviations
export const Global: React.FC<StyledGlobalProperties> = ({
    $isMaintenancePage,
    theme,
}) => {
    const styles = getGlobalStyles({ $isMaintenancePage, theme });
    return <GlobalStyles styles={styles} />;
};
