import { Nullable } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    isAxiosError,
    OptimizelyEvent,
    OptimizelyEventAttributes,
    TggLogger,
} from '@tgg/util';

export const trackOptimizelyEventClient = async (
    optimizelyEvent: OptimizelyEvent,
    attributes: OptimizelyEventAttributes,
    options: {
        logger: Nullable<TggLogger>;
    },
) => {
    const { logger } = options;
    try {
        await axiosInstance.post('/api/proxy/optimizely-event/', {
            event: optimizelyEvent,
            attributes,
        });
    } catch (error) {
        if (isAxiosError(error)) {
            logger?.error(
                `clientside POST /api/proxy/optimizely-event/ ${JSON.stringify(
                    error.response?.data,
                )}`,
                {
                    correlationId: attributes.userId,
                },
            );
        } else {
            logger?.error(
                `clientside POST /api/proxy/optimizely-event/ ${
                    (error as Error).message
                }`,
                {
                    correlationId: attributes.userId,
                },
            );
        }
    }
};
