/* eslint-disable react/sort-comp */
import {
    ApplicationInsights,
    IConfiguration,
    IConfig,
} from '@microsoft/applicationinsights-web';
import { AppProps } from 'next/app';
import * as React from 'react';

interface WithApplicationInsightsProperties {
    pageName: string;
}
export interface ICustomConfig {
    isEnabled: boolean;
}

type ConfigType = IConfiguration & IConfig & ICustomConfig;

export function withApplicationInsights(config: ConfigType) {
    return (App: any) => {
        return class WithApplicationInsights extends React.Component<
            WithApplicationInsightsProperties & AppProps
        > {
            public appInsights?: ApplicationInsights;

            public componentDidMount() {
                this.initializeAppInsights();
                this.trackPageView();
            }

            public componentDidCatch(error: Error) {
                if (this.appInsights) {
                    this.appInsights.trackException({ exception: error });
                }
            }

            public initializeAppInsights() {
                if (config.isEnabled && !this.appInsights) {
                    this.appInsights = new ApplicationInsights({ config });
                    this.appInsights.loadAppInsights();
                    window.appInsights = this.appInsights;
                }
            }

            public trackPageView() {
                if (this.appInsights) {
                    const {
                        Component,
                        router: { route },
                    } = this.props;
                    const { displayName, name } = Component;
                    // eslint-disable-next-line no-restricted-globals
                    const pathName = location.pathname;
                    const documentTitle = displayName || name || pathName;
                    const properties = { route };
                    this.appInsights.trackPageView({
                        name: documentTitle,
                        properties,
                    });
                }
            }

            public render() {
                this.trackPageView();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return React.createElement(App, this.props);
            }
        };
    };
}
