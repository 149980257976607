const articles = new Set(['a', 'an', 'the']);
const conjunctions = new Set(['for', 'and', 'nor', 'but', 'or', 'yet', 'so']);
const prepositions = new Set([
    'around',
    'by',
    'after',
    'along',
    'for',
    'from',
    'of',
    'on',
    'to',
    'with',
    'without',
]);

function kebabCaseToTitleCase(kebab: string): string {
    return kebab
        .split('-')
        .map((word, index) => {
            if (
                index > 0 &&
                (articles.has(word) ||
                    conjunctions.has(word) ||
                    prepositions.has(word))
            )
                return word;
            return word[0].toUpperCase() + word.slice(1);
        })
        .join(' ');
}

export function urlSlugToPageTitle(slug: string): string {
    if (!slug) return 'The Gym Group';

    const segments = slug
        .split('/')
        .filter(segment => !!segment)
        .map(segment => kebabCaseToTitleCase(segment));

    return [...segments.reverse(), 'The Gym Group'].join(' | ');
}
