import { SnackBar } from '../SnackBar';
import { StyledLink } from './HeaderInfoSnackBar.styled';
import { HeaderInfoSnackBarProperties } from './HeaderInfoSnackBar.types';

/**
 * Info snackbar component to be displayed in th e header.
 */
export function HeaderInfoSnackBar({
    id,
    message,
    iconName = 'announcements',
    barType = 'info',
    link,
}: HeaderInfoSnackBarProperties) {
    return link ? (
        <StyledLink data-testid="header info link" href={link}>
            <SnackBar
                id={id}
                message={message}
                barType={barType}
                iconName={iconName}
            />
        </StyledLink>
    ) : (
        <SnackBar
            id={id}
            message={message}
            barType={barType}
            iconName={iconName}
        />
    );
}

export default HeaderInfoSnackBar;
