export function getRobotsMetaData(robotsMetaData: string): string {
    if (!robotsMetaData) return 'index, follow';
    return robotsMetaData;
}

export function checkAndAddTrailingSlash(string: string) {
    if (string.endsWith('/')) return string;
    return `${string}/`;
}

/**
 * Uses a combination of input parameters to generate the a full canonical URL
 * @param canonicalURL will be passed from amplience e.g https://thegymgroup.com/test/ or test
 * @param baseUrl is the domain level URL e.g https://thegymgroup.com
 * @param relativePath is the path of current page e.g /find-a-gym/croydon-east-london
 */
export function getCanonicalURL(
    canonicalURL: string,
    baseUrl: string,
    relativePath: string,
): string {
    if (!canonicalURL) {
        return checkAndAddTrailingSlash(
            `${baseUrl}${relativePath.split('?')[0]}`,
        );
    }

    if (canonicalURL.startsWith('http')) {
        return checkAndAddTrailingSlash(canonicalURL);
    }

    if (canonicalURL.startsWith('/')) {
        return checkAndAddTrailingSlash(`${baseUrl}${canonicalURL}`);
    }

    return checkAndAddTrailingSlash(`${baseUrl}/${canonicalURL}`);
}
