import { AnyContext } from '../cookies';
import { getOptimizelyUserId } from './cookie/featureFlagsCookie';
import {
    TggFlagsObject,
    type FeatureFlagsRequestOptions,
} from './featureFlags.types';
import { getFeatureFlagRequestOptions } from './helpers/featureFlagsHelpers';
import { optimizelyFlagsGetterTggWrapper } from './optimizely/optimizelyApi';
import { OptimizelyFeatureFlags } from '@tgg/common-types';

type FeatureFlagsRequestOverrides = {
    overrideUserId: string;
    pathname: string;
};

export const getAllFeatureFlagsSsr = async (
    context: AnyContext,
    options: FeatureFlagsRequestOptions & {
        overrides?: Partial<FeatureFlagsRequestOverrides>;
    },
) => {
    const { pathname } = getFeatureFlagRequestOptions(context);
    const { resolvedId: userId } = await getOptimizelyUserId(context);

    const { overrides } = options;
    const flagsUserId = overrides?.overrideUserId || userId;
    const flagsPathname = overrides?.pathname || pathname;

    const flagsObject = await optimizelyFlagsGetterTggWrapper(
        'ALL',
        flagsUserId,
        {
            ...options,
            pathname: flagsPathname,
        },
    );

    return {
        flags: flagsObject.result,
        pathname: flagsPathname,
        userId: flagsUserId,
        error: flagsObject.error,
    };
};

export const getFeatureFlagSsr = async <T extends OptimizelyFeatureFlags>(
    context: AnyContext,
    key: T,
    options: FeatureFlagsRequestOptions & {
        overrides?: Partial<FeatureFlagsRequestOverrides>;
    },
) => {
    const { pathname } = getFeatureFlagRequestOptions(context);
    const { resolvedId: userId } = await getOptimizelyUserId(context);

    const { overrides } = options;
    const flagsUserId = overrides?.overrideUserId || userId;
    const flagsPathname = overrides?.pathname || pathname;

    const flagResult = await optimizelyFlagsGetterTggWrapper(key, flagsUserId, {
        ...options,
        pathname: flagsPathname,
    });

    return {
        flag: flagResult.result as TggFlagsObject[T],
        pathname: flagsPathname,
        userId: flagsUserId,
        error: flagResult.error,
    };
};
