import {
    ITraceTelemetry,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';

import { ClientLogger, SeverityLevelsType } from './clientLogger.types';

const baseLogger = (
    message: string,
    level: SeverityLevelsType,
    applicationIdentifier: string,
) => {
    if (typeof window !== 'undefined' && window.appInsights) {
        const { appInsights } = window;
        const telemetry: ITraceTelemetry = {
            message,
            severityLevel: SeverityLevel[level],
            properties: {
                Application: applicationIdentifier,
                UserAgent: window.navigator.userAgent,
            },
        };
        appInsights.trackTrace(telemetry);
    }
};

export const clientLogger: ClientLogger = {
    out(message: string, applicationIdentifier: string) {
        baseLogger(message, 'Verbose', applicationIdentifier);
    },
    info(message: string, applicationIdentifier: string) {
        baseLogger(message, 'Information', applicationIdentifier);
    },
    warn(message: string, applicationIdentifier: string) {
        baseLogger(message, 'Warning', applicationIdentifier);
    },
    error(message: string, applicationIdentifier: string) {
        baseLogger(message, 'Error', applicationIdentifier);
    },
    critical(message: string, applicationIdentifier: string) {
        baseLogger(message, 'Critical', applicationIdentifier);
    },
};
