import { useRouter } from 'next/router';

import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import { NavigationItem } from '../../NavigationItem';
import {
    StyledSlide,
    StyledList,
    StyledSubMenuPaper,
    StyledHorizontalRule,
    BackIconContainer,
    StyledHeadingBackLink,
    StyledBackButton,
} from '../SideNavigation.styled';
import {
    StyledHeadingSubMenu,
    StyledListSubMenuTop,
} from './SlideGymListNavItems.styled';
import { SlideGymListNavItemsProperties } from './SlideGymListNavItems.types';
import { isMemberArea, getAbsoluteURL } from '@tgg/util';

export function SlideGymListNavItems({
    items,
    isOpen,
    onBack,
    logSideNavRedirect,
}: SlideGymListNavItemsProperties) {
    const router = useRouter();
    const isInMemberArea = isMemberArea(router.basePath);
    const { common } = palette;
    const findAGymHeaderText = 'FIND A GYM';
    const findAGymLink = '/find-a-gym';
    return (
        <StyledSlide direction="left" in={isOpen}>
            <StyledSubMenuPaper $isHeightAuto={isOpen}>
                <BackIconContainer onClick={onBack}>
                    <StyledBackButton aria-label="Back to the main menu">
                        <Icon
                            name="chevronLeft"
                            size={12}
                            color={common.blue}
                        />
                    </StyledBackButton>

                    <StyledHeadingBackLink variant="h3" gutterBottom={false}>
                        {findAGymHeaderText}
                    </StyledHeadingBackLink>
                </BackIconContainer>
                <StyledHorizontalRule />
                <StyledListSubMenuTop>
                    <NavigationItem
                        text="Find a Gym?"
                        leftIconName="gymFinder"
                        href={
                            isInMemberArea
                                ? getAbsoluteURL(findAGymLink)
                                : findAGymLink
                        }
                        divider={false}
                        isSubMenuAlternativeItem
                    />
                </StyledListSubMenuTop>

                <StyledHeadingSubMenu variant="h3" gutterBottom={false}>
                    GYMS BY CITIES & REGIONS
                </StyledHeadingSubMenu>
                <StyledHorizontalRule />
                <StyledList>
                    {items.map((subMenuItem, index) => {
                        return (
                            <NavigationItem
                                caseSensitive={subMenuItem.caseSensitive}
                                key={`item${subMenuItem.text}`}
                                href={
                                    isInMemberArea
                                        ? getAbsoluteURL(subMenuItem.href)
                                        : subMenuItem.href
                                }
                                onClick={() =>
                                    logSideNavRedirect({
                                        menuParentText: findAGymHeaderText,
                                        menuItemText: subMenuItem.text,
                                        redirectUrl: subMenuItem.href,
                                    })
                                }
                                text={subMenuItem.text}
                                divider={index !== items.length - 1}
                            />
                        );
                    })}
                </StyledList>
            </StyledSubMenuPaper>
        </StyledSlide>
    );
}
