import { useRouter } from 'next/router';
import { useReducer, useEffect, Reducer, useMemo } from 'react';

import { SnackBarContext } from './snackBarContext';
import {
    SnackBarActions,
    SnackBarProviderProperties,
    SnackBarState,
    SnackBarContextProperties,
} from './snackBarContext.types';

const defaultState = {
    content: '',
    barType: 'none',
    iconName: 'announcements',
} as const;

const snackBarReducer: Reducer<SnackBarState, SnackBarActions> = (
    state,
    action,
) => (action.barType === 'none' ? defaultState : { ...state, ...action });

export function SnackBarProvider({ children }: SnackBarProviderProperties) {
    const [state, dispatch] = useReducer(snackBarReducer, defaultState);
    const router = useRouter();

    useEffect(() => {
        const routeChangeHandler = () => {
            if (state.content) {
                dispatch({ barType: 'none' });
            }
        };

        router.events.on('routeChangeStart', routeChangeHandler);

        return () => {
            router.events.off('routeChangeStart', routeChangeHandler);
        };
    }, [router.events, state.content]);

    const contextValue = useMemo<SnackBarContextProperties>(
        () => [state, dispatch],
        [state, dispatch],
    );

    return (
        <SnackBarContext.Provider value={contextValue}>
            {children}
        </SnackBarContext.Provider>
    );
}
