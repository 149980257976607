/* istanbul ignore file */
import { createContext } from 'react';

import { BackToTopContextProperties } from './backToTopContext.types';

export const BackToTopContext = createContext<BackToTopContextProperties>({
    elementReference: null,
    additionalOffset: 0,
    setElementReference: () => {},
    setAdditionalOffset: () => {},
});
