import { type NextApiHandler } from 'next/types';

import {
    ApiError,
    type TggFlagsObject,
    type TggLogger,
    createApiService,
    createLoggerContext,
    createTraceParameters,
    getAllFeatureFlagsSsr,
} from '@tgg/util';

export type PostOptimizelyFlagsRequestBody = {
    overrideUserId?: string;
    pathname: string;
};

export type TggProxyHandler = NextApiHandler<any> & {
    get: (function_: NextApiHandler) => NextApiHandler<any>;
    post: (function_: NextApiHandler) => NextApiHandler<any>;
};

export const setupOptimizelyFlagsHandler = (
    appLogger: TggLogger,
    handler: TggProxyHandler,
) =>
    handler.post(async (request, response) => {
        const context = createLoggerContext(request, response);

        const loggerParameters = createTraceParameters({
            context,
            parameters: { operation: 'optimizely-flags' },
        });

        try {
            const { overrideUserId, pathname } =
                request.body as PostOptimizelyFlagsRequestBody;

            const { flags, error, userId } = await getAllFeatureFlagsSsr(
                context,
                {
                    logger: appLogger,
                    overrides: {
                        overrideUserId,
                        pathname,
                    },
                },
            );

            if (error) {
                appLogger.error(
                    `api/proxy/optimizely-flags failed, default flags returned for the user: ${userId};
                error: ${(error as Error).message}`,
                    loggerParameters,
                );
            }

            response.status(200).json(flags);
        } catch (error_) {
            const error = error_ as Error;
            appLogger.error(
                `api/proxy/optimizely-event failed: ${error.message}`,
                loggerParameters,
            );

            throw new ApiError('api/proxy/optimizely-event failed', 500);
        }
    });

export const optimizelyFlagsService = createApiService<
    'post',
    TggFlagsObject,
    PostOptimizelyFlagsRequestBody
>('post', {
    path: '/api/proxy/optimizely/flags',
    unCapitalizeProperties: true,
});
