import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Heading } from '../Heading';
import { Icon } from '../Icon';

export const StyledDialogTitle = styled('div')`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 3rem 2rem 3rem;
    color: ${palette.common.white};
    background-color: ${palette.primary.main};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 3rem;
    }
`;

export const StyledDialogContent = styled('div')<{
    $hasPadding: boolean;
    $isFullHeight: boolean;
    $maxWidth?: number;
    $customPadding?: string;
}>`
    max-width: ${({ $maxWidth }) => $maxWidth && `${$maxWidth}rem`};
    height: ${({ $isFullHeight }) => $isFullHeight && '100%'};
    padding: ${({ $hasPadding }) => $hasPadding && '2.2rem 3rem'};
    color: ${palette.common.blue};
`;

export const StyledHeading = styled(Heading)<{
    $maxWidth?: number;
}>`
    margin: 0;
    padding-right: 2rem;
    color: ${palette.common.white};
    font-size: 2.8rem;
    line-height: 3rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${({ $maxWidth }) =>
            $maxWidth && `calc(${$maxWidth}rem*0.8)`};
        padding-right: 0;
        font-size: 3.6rem;
        line-height: 3.8rem;
    }
`;

export const StyledCloseButton = styled('button')`
    display: flex;
    height: fit-content;
    margin: 0;
    padding: 0.7rem 0 0 0;
    background-color: inherit;
    border: none;
    cursor: pointer;
    svg {
        margin: auto;
    }
`;

export const StyledIcon = styled(Icon)`
    cursor: pointer;
`;
