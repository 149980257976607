import { styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

import { Svg } from '../Svg';
import { SvgProperties } from '../Svg/Svg.types';

export const PathWrapper = styled('g')``;

export const StyledSvg = styled(Svg)<PropsWithChildren<SvgProperties>>`
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`;
