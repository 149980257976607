/* istanbul ignore file */

import AgentKeepAlive, { HttpsAgent } from 'agentkeepalive';

export const httpKeepAliveAgent = new AgentKeepAlive({
    keepAlive: true,
    maxSockets: 10,
});

export const httpsKeepAliveAgent = new HttpsAgent({
    keepAlive: true,
    maxSockets: 10,
});
