import { createContext } from 'react';

import { MenuItemsType } from '../../components/SideNavigation';
import { commonMenuItems } from './menuItemsDefaults';

export type MenuItemsContextProperties = {
    menuItems: MenuItemsType;
};

export const MenuItemsContext = createContext<MenuItemsContextProperties>({
    menuItems: commonMenuItems,
});
