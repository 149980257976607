import { useEffect } from 'react';

import { dispatchOptimizelyGaDecisionEvent } from '../helpers/dispatchOptimizelyGaEvent';
import { OptimizelyClientHookConfig } from './useOptimizelyFeClient';
import { useOptimizelyTracking } from './useOptimizelyTracking';
import { type TggFlagsObject } from '@tgg/util';

export const useOptimizelyBeClient = (
    config: OptimizelyClientHookConfig & { resolvedFlags: TggFlagsObject },
) => {
    const {
        resolvedFlags,
        resolvedUserId,
        useClient,
        isUserInitialized,
        pathname,
        isRendered,
        setRender,
    } = config;

    useEffect(() => {
        if (isUserInitialized && useClient) {
            setRender(true);
        }
    }, [isUserInitialized, useClient, setRender]);

    useEffect(() => {
        if (isUserInitialized && resolvedFlags && useClient && !isRendered) {
            Object.keys(resolvedFlags).forEach(flagKey => {
                const flag = resolvedFlags[flagKey as keyof TggFlagsObject];
                // @ts-ignore
                if (flag.enabled && flag.variables?.enableDataLayer === true) {
                    dispatchOptimizelyGaDecisionEvent({
                        flagKey,
                        flagsUserId: resolvedUserId,
                        pathname,
                        ruleKey: flag.ruleKey,
                        variationKey: flag.variationKey,
                    });
                }
            });
        }
    }, [
        isRendered,
        isUserInitialized,
        resolvedFlags,
        resolvedUserId,
        pathname,
        useClient,
    ]);

    const { trackFlagsEvent } = useOptimizelyTracking({
        pathname,
        resolvedUserId,
    });

    return { trackFlagsEvent };
};
