/* istanbul ignore file */

import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledTermsWrapper = styled('small')`
    display: block;
    padding-top: ${({ theme }) => theme.spacing(20)};
    padding-right: ${({ theme }) => theme.spacing(30)};
    padding-bottom: ${({ theme }) => theme.spacing(36)};
    padding-left: ${({ theme }) => theme.spacing(30)};
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.6rem;
    background-color: ${palette.common.white};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-right: 30rem;
        padding-left: 30rem;
        font-size: 1.2rem;
        text-align: center;
    }
`;

export const StyledAsterisk = styled('span')`
    color: ${palette.primary.main};
`;
