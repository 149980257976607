export function debounce<T extends any[]>(
    function_: (...rest: T) => void,
    timeout: number,
) {
    let timeoutReference: NodeJS.Timeout | null = null;

    return (...arguments_: T) => {
        if (timeoutReference !== null) {
            clearTimeout(timeoutReference);
        }
        timeoutReference = setTimeout(() => function_(...arguments_), timeout);
    };
}
