import getConfig from 'next/config';

import { isLowerEnv } from '../isLowerEnv';

export const getTestId = (id: string): string | undefined => {
    const APP_ENVIRONMENT =
        typeof getConfig === 'function' &&
        getConfig()?.publicRuntimeConfig?.APP_ENVIRONMENT;

    const envString =
        APP_ENVIRONMENT ||
        process.env.NX_APP_ENVIRONMENT ||
        process.env.NODE_ENV ||
        'unknown';

    return isLowerEnv(envString) ? id : undefined;
};
