import cookie, { CookieSerializeOptions } from 'cookie';
import { addDays } from 'date-fns';
import { CookieAttributes } from 'js-cookie';
import { toLower } from 'lodash';

import {
    AVAILABLE_COOKIES,
    AvailableCookieBuckets,
    CookieKey,
} from '../cookies.types';
import { ApplicationIdentifier } from '@tgg/common-types';

/**
 * default sameSite cookie option when not provided
 */
const defaultSameSite = 'lax';

/**
 * default expires/maxAge cookie option when not provided
 */
const defaultExpires = 7;

/**
 * generate cookie key from cookie name and bucket
 * when bucket set to common returns cookie name
 * when bucket set to ApplicationIdentifier returns cookieName-cookieBucket
 */
export const composeFullCookieName = <
    D extends AVAILABLE_COOKIES,
    T extends AvailableCookieBuckets,
>(
    cookieName: D,
    cookieBucket: T,
): CookieKey =>
    cookieBucket === 'common'
        ? cookieName
        : `${cookieName}-${cookieBucket as ApplicationIdentifier}`;

/**
 * sets cookie path option to /member-area for higher envs
 */
export const resolveCookiePath = (
    cookieKey: CookieKey,
    appEnvironment?: string,
) =>
    cookieKey.includes('member') && appEnvironment !== 'dev'
        ? '/member-area'
        : '/';

/**
 * upsert - spread when object, replace whole value otherwise
 */
export const parseCookieUpsert = <C>(
    currentValue: C | null,
    value: Partial<C>,
): C => {
    return (
        typeof currentValue === 'object' &&
        currentValue !== null &&
        !Array.isArray(currentValue)
            ? {
                  ...(currentValue as Record<string, any>),
                  ...value,
              }
            : value
    ) as C;
};

/**
 * ^[[{] test if object or array, to prevent double stringification
 */
export const stringifyCookieValue = (value: any) => {
    try {
        const result = JSON.stringify(value);
        return /^[[{]/.test(result) ? result : value;
    } catch {
        /* istanbul ignore next */ return value;
    }
};

/**
 * reversed cookie.parse, replace %22 with "
 */
export const decodeCookieValue = (string_: string): string => {
    if (!string_) return string_;

    // eslint-disable-next-line security/detect-unsafe-regex
    return string_.replace(/(%[\dA-Z]{2})+/g, decodeURIComponent);
};

/**
 * unified cookie options for browser/ssr
 * does not set maxAge (browser doesn't allow it)
 * returns browser/ssr unified type CookieAttributes (js-cookie) & CookieSerializeOptions (cookie)
 */
export const convertCookieOptions = (
    cookieOptions: CookieSerializeOptions | CookieAttributes,
    cookieKey: CookieKey,
    environment?: string,
    now?: Date,
): CookieAttributes & CookieSerializeOptions => {
    const newSameSite =
        typeof cookieOptions.sameSite === 'string'
            ? (toLower(cookieOptions.sameSite) as 'strict' | 'lax' | 'none')
            : typeof cookieOptions.sameSite === 'boolean'
            ? cookieOptions.sameSite
                ? 'strict'
                : 'none'
            : cookieOptions.sameSite ?? defaultSameSite;

    const newProvidedCookieOptionsExpires =
        typeof cookieOptions.maxAge === 'number'
            ? addDays(now ?? new Date(), cookieOptions.maxAge / (24 * 60 * 60))
            : addDays(now ?? new Date(), defaultExpires);

    const newExpires =
        typeof cookieOptions.expires === 'number'
            ? addDays(now ?? new Date(), cookieOptions.expires)
            : newProvidedCookieOptionsExpires;

    const newPath = cookieOptions.path
        ? cookieOptions.path
        : resolveCookiePath(cookieKey, environment);

    return {
        ...cookieOptions,
        sameSite: newSameSite,
        expires: newExpires,
        path: newPath,
    };
};

/**
 * unified cookie options for ssr
 * sets maxAge for ssr
 * returns ssr compatibile type CookieSerializeOptions (cookie)
 */
export const convertCookieOptionsToSsr = (
    cookieOptions: CookieSerializeOptions | CookieAttributes,
    cookieKey: CookieKey,
    environment?: string,
    now?: Date,
): CookieSerializeOptions => {
    const convertedOptions = convertCookieOptions(
        cookieOptions,
        cookieKey,
        environment,
        now,
    );

    const providedCookieOptionsExpires =
        typeof cookieOptions.expires === 'number'
            ? cookieOptions.expires * 24 * 60 * 60
            : defaultExpires * 24 * 60 * 60;

    const newMaxAge =
        typeof cookieOptions.maxAge === 'number'
            ? cookieOptions.maxAge
            : providedCookieOptionsExpires;

    return {
        ...convertedOptions,
        maxAge: newMaxAge,
    };
};

export const serializeCookieForServerside = (
    cookieKey: string,
    value: any,
    cookieOptions: CookieSerializeOptions,
) => {
    const serializedValue =
        typeof value !== 'string' ? JSON.stringify(value) : value;
    const serializedCookie = cookie.serialize(
        cookieKey,
        serializedValue,
        cookieOptions,
    );

    return serializedCookie;
};
