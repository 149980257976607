import { type OptimizelyDecision } from '@optimizely/optimizely-sdk';
import {
    type ReactSDKClient,
    createInstance,
    type ListenerPayload,
    enums,
} from '@optimizely/react-sdk';

import { dispatchOptimizelyGaDecisionEvent } from '../helpers/dispatchOptimizelyGaEvent';
import { type Nullable } from '@tgg/common-types';

let optimizelyFeClient: Nullable<ReactSDKClient> = null;

export type DecisionEvent = ListenerPayload & {
    decisionInfo: OptimizelyDecision; // be careful here, exact event shape type not available
};

export const decisionListener = async (
    event: DecisionEvent,
    config: {
        resolvedUserId: string;
        pathname: string;
    },
) => {
    const { resolvedUserId, pathname } = config;
    const { enabled, ruleKey, variationKey, variables, flagKey } =
        event.decisionInfo;

    /* istanbul ignore else */
    if (variables?.enableDataLayer === true) {
        dispatchOptimizelyGaDecisionEvent({
            flagKey,
            flagsUserId: resolvedUserId,
            pathname,
            ruleKey,
            variationKey,
        });
    }
};

export const attachDecisionEventListeners = async (
    client: ReactSDKClient,
    config: {
        resolvedUserId: string;
        pathname: string;
        onDone: () => void;
    },
) => {
    const { onDone, ...eventConfig } = config;

    client.notificationCenter.clearNotificationListeners(
        enums.NOTIFICATION_TYPES.DECISION,
    );

    client.notificationCenter.addNotificationListener<DecisionEvent>(
        enums.NOTIFICATION_TYPES.DECISION,
        /* istanbul ignore next */
        event => decisionListener(event, eventConfig),
    );

    onDone();
};

export const initOptimizelyFeClient = async (config: {
    sdkKey: string;
    flagsUserId: string;
    successCallback: (client: ReactSDKClient) => void;
    errorCallback: (error: unknown) => void;
}) => {
    const { sdkKey, errorCallback, successCallback, flagsUserId } = config;

    const isClientInitialized =
        optimizelyFeClient &&
        optimizelyFeClient?.getOptimizelyConfig() !== null;

    const isUserSet =
        isClientInitialized && optimizelyFeClient?.user.id === flagsUserId;

    try {
        if (!isClientInitialized) {
            optimizelyFeClient = createInstance({ sdkKey });

            await optimizelyFeClient.onReady();
            if (optimizelyFeClient?.getOptimizelyConfig() !== null) {
                await optimizelyFeClient?.setUser({
                    id: flagsUserId,
                });

                successCallback(optimizelyFeClient);
            } else {
                await optimizelyFeClient.close();
                optimizelyFeClient = null;
                throw new Error('Optimizely client returned empty config');
            }
        } else {
            if (!isUserSet)
                await optimizelyFeClient?.setUser({
                    id: flagsUserId,
                });
            successCallback(optimizelyFeClient as ReactSDKClient);
        }
    } catch (error) {
        errorCallback(error);
    }
};
