export default {
    aboutTheGymGroup: [
        {
            name: 'About Us',
            href: '/about-us/',
        },
        {
            name: "The Gym Group's History",
            href: '/the-gym-group-history/',
        },
        {
            name: 'Awards',
            href: '/awards/',
        },
        {
            name: 'Press Area',
            href: '/press-area/',
        },
        {
            name: 'Contact Us',
            href: '/contact-us/',
        },
        {
            name: 'help & support',
            href: 'https://support.thegymgroup.com',
            external: true,
        },
    ],
    /**
     * Commented out links are TBC - https://tggitdev.atlassian.net/browse/MAUI-369
     */
    theGyms: [
        {
            name: 'Membership Rules',
            href: '/legal/the-gym-group-membership-rules/',
        },
        {
            name: 'Classes',
            href: '/gym-classes/',
        },

        {
            name: 'Personal Training At The Gym Group',
            href: '/personal-training/',
        },
        {
            name: 'Exercise Hub',
            href: '/exercises/',
        },
        {
            name: 'Gym Opening Times',
            href: '/gym-opening-times/',
        },
        {
            name: 'Gym Tour',
            href: '/gym-tour/',
        },
    ],
    corporate: [
        {
            name: 'Corporate Membership',
            href: '/corporate-membership/',
        },
        {
            name: 'Property Information',
            href: '/property-info/',
        },
        {
            name: 'Careers',
            href: '/careers/',
        },
        {
            name: 'Corporate Site',
            href: 'https://www.tggplc.com/',
            external: true,
        },
    ],
    legal: [
        {
            name: 'Membership Agreement',
            href: '/legal/membership-agreement/',
        },
        {
            name: 'Accessibility',
            href: '/accessibility/',
        },
        {
            name: 'Legal Stuff',
            href: '/legal/',
        },
        {
            name: 'Privacy & Cookie Policy',
            href: '/privacy-cookie-policy/',
        },
    ],
};
