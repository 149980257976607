import { styled } from '@mui/material/styles';

import { Theme } from '../../../theme';
import { Heading } from '../../Heading';
import { Icon } from '../../Icon';

export const StyledMenuHeader = styled('header')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: ${({ theme }: { theme: Theme }) => theme.spacing(30, 30, 0, 30)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 3rem;
    }
`;

export const HeaderWithIcon = styled('div')`
    display: flex;
    align-items: center;
    justify-content: start;
`;

export const StyledMenuIcon = styled(Icon)`
    margin-right: 1.1rem;
`;

export const StyledHeadingHamBurger = styled(Heading)`
    font-size: 2.8rem;
    line-height: 3.1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.6rem;
    }
`;

export const StyledCloseButton = styled('button')`
    width: 2.2rem;
    height: 2.2rem;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
`;
