import { useRouter } from 'next/router';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

interface UseGymTourOverlayProperties {
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export function useOverlay({ setMenuOpen }: UseGymTourOverlayProperties) {
    const router = useRouter();
    const [isOpen, setOverlayOpen] = useState(false);

    useEffect(() => {
        if (router.query && router.query['gym-tour-overlay'] === '') {
            setOverlayOpen(true);
            setMenuOpen(false);
        }
    }, [router.query, setMenuOpen]);

    const handleClose = useCallback(async () => {
        await router.replace({ query: null });
        setOverlayOpen(false);
    }, [router]);

    return { handleClose, isOpen };
}
