import { palette } from '../../theme';
import { ButtonBase } from '../Button';
import { StyledWrapper } from './StickyNavigationButton.styled';
import { StickyNavigationButtonProperties } from './StickyNavigationButton.types';
import { getTestId } from '@tgg/util';

export const StickyNavigationButton = ({
    buttonText,
    href,
    onClick,
    isButtonFullWidth = true,
    backgroundColor = palette.grey[100],
    children,
    testId,
}: StickyNavigationButtonProperties) => {
    return (
        <StyledWrapper $backgroundColor={backgroundColor}>
            {children}
            <ButtonBase
                text={buttonText}
                href={href}
                onClick={onClick}
                fullWidth={isButtonFullWidth}
                data-testid={getTestId(`${testId}-button`)}
            />
        </StyledWrapper>
    );
};
