import { CookieSerializeOptions } from 'cookie';
import { CookieAttributes } from 'js-cookie';
import { DocumentContext } from 'next/document';
import { GetServerSidePropsContext, NextPageContext } from 'next/types';

import { ApplicationIdentifier } from '@tgg/common-types';

export enum AVAILABLE_COOKIES {
    'DEFAULT_BASKET' = 'basket',
    'PAYMENT_TOKEN' = 'paymentToken',
    'USER_IDENTIFIER' = 'tggUserCorrelationId',
    'USER_IDENTIFIER_DEPRECATED' = 'userCorrelationId',
    'MOBILE_AUTH' = 'mobileAuth',
    'FEATURE_FLAGS' = 'featureFlagsHash',
    'CANCEL_LITE' = 'cancelLite',
    'RENEWAL' = 'renewal',
    'GA4_USER_ID' = '_ga',
    'OPTIMIZELY_USER_ID' = 'optimizelyEndUserId',
    'GUEST_PASS' = 'guestPass',
}

export type AvailableCookieBuckets = ApplicationIdentifier | 'common';

export type CookieKey =
    | `${AVAILABLE_COOKIES}`
    | `${AVAILABLE_COOKIES}-${ApplicationIdentifier}`;

export interface CookieParameters {
    cookieParameters: CookieAttributes;
}

export interface CookieParametersSsr {
    cookieParameters: CookieAttributes | CookieSerializeOptions;
}

export interface CookieParametersDocument {
    cookieParameters: CookieAttributes | CookieSerializeOptions;
}

export interface GetCookie {
    cookieKey: CookieKey;
    environment?: string;
}

export interface SetCookie<C> extends GetCookie, CookieParameters {
    value: C;
}

export interface UpdateCookie<C> extends GetCookie, CookieParameters {
    value: Partial<C>;
}

export type DeleteCookie = GetCookie & CookieParameters;

export type NonPageContext = DocumentContext | NextPageContext;
export type AnyContext = GetServerSidePropsContext | NonPageContext;

export type GetCookieSsr = GetCookie & {
    context: AnyContext;
};

export type ExpireCookieSsr<C> = GetCookieSsr &
    CookieParametersSsr & {
        emptyValue: Partial<C>;
    };

export type DeleteCookieSsr = GetCookieSsr;

export type GetCookieDocument = GetCookie & { context: DocumentContext };

export interface SetCookieSsr<C> extends GetCookieSsr, CookieParametersSsr {
    value: C | Record<string, never> | '';
}

export interface SetCookieDocument<C>
    extends GetCookieDocument,
        CookieParametersDocument {
    value: C | Record<string, never> | '';
}

export interface UpdateCookieSsr<C> extends GetCookieSsr, CookieParametersSsr {
    value: Partial<C>;
}

export interface UpdateCookieDocument<C>
    extends GetCookieDocument,
        CookieParametersDocument {
    value: Partial<C>;
}

export interface ApiForCookieSsr<C> {
    getCookieSsr: (context: AnyContext) => C | null;
    expireCookieSsr: (
        context: AnyContext,
        cookieParameters?: CookieAttributes,
        environment?: string,
    ) => void;
    deleteCookieSsr: (context: AnyContext) => void;
    setCookieSsr: (
        context: AnyContext,
        value: C,
        cookieParameters?: CookieAttributes,
        environment?: string,
    ) => void;
    updateCookieSsr: (
        context: AnyContext,
        value: Partial<C>,
        cookieParameters?: CookieAttributes,
        environment?: string,
    ) => void;
}

export interface ApiForCookie<C> {
    getCookie: () => C | null;
    deleteCookie: (
        cookieParameters?: CookieAttributes,
        environment?: string,
    ) => void;
    updateCookie: (
        value: Partial<C>,
        cookieParameters?: CookieAttributes,
        environment?: string,
    ) => void;
    setCookie: (
        value: C,
        cookieParameters?: CookieAttributes,
        environment?: string,
    ) => void;
}
