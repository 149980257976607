/* istanbul ignore file */
import axios from 'axios';

import { httpKeepAliveAgent, httpsKeepAliveAgent } from './agentKeepAlive';

export const axiosInstance = axios.create({
    timeout: 20_000,
    httpAgent: httpKeepAliveAgent,
    httpsAgent: httpsKeepAliveAgent,
});
