/* istanbul ignore file */
import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';

/**
 * Represents the levels of horizontal padding to align the footer items in a grid-like fashion
 * Mobile has two different levels - outer (e.g. the accordion container) and inner (e.g. the headings inside the accordion)
 * Desktop just has one level
 */
export const spacingPx = {
    outer: {
        mobile: 30,
        desktop: 80,
    },
    inner: {
        mobile: 20,
    },
};

export const StyledFooterWrapper = styled('footer')`
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing(32)};
    background-color: ${palette.grey[100]};
`;

export const StyledFooterHeading = styled(Heading)`
    padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(15)};
    font-weight: normal;
    font-size: 1.4rem;
    font-family: thegymgroupsans-Headline;
    line-height: 1.6rem;
    text-transform: lowercase;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(15)};
        padding-bottom: 0;
        font-size: 1.6rem;
        line-height: 1.8rem;
    }
`;

export const StyledTopSection = styled('div')`
    padding-top: ${({ theme }) => theme.spacing(spacingPx.inner.mobile)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        justify-content: space-between;
        padding-right: ${({ theme }) => theme.spacing(spacingPx.outer.desktop)};
        padding-left: ${({ theme }) => theme.spacing(spacingPx.outer.desktop)};
    }
`;

export const StyledSocialIconsSection = styled('section')`
    padding-right: ${({ theme }) =>
        theme.spacing(spacingPx.outer.mobile + spacingPx.inner.mobile)};
    padding-left: ${({ theme }) =>
        theme.spacing(spacingPx.outer.mobile + spacingPx.inner.mobile)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-left: 0;
    }
`;

export const StyledSocialIconsList = styled('ul')`
    display: flex;
    gap: 2rem;
    width: 100%;
    margin: 0;
    padding-left: 0;
    list-style: none;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-top: ${({ theme }) => theme.spacing(15)};
    }
`;

export const StyledHorizontalRuleContainer = styled('div')`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-top: 3rem;
        padding-right: ${({ theme }) => theme.spacing(spacingPx.outer.desktop)};
        padding-left: ${({ theme }) => theme.spacing(spacingPx.outer.desktop)};
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    background-color: ${palette.common.blue};
`;

export const StyledSiteLinksSection = styled('div')`
    padding-top: ${({ theme }) => theme.spacing(spacingPx.inner.mobile)};
    padding-right: ${({ theme }) => theme.spacing(spacingPx.outer.mobile)};
    padding-left: ${({ theme }) => theme.spacing(spacingPx.outer.mobile)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        justify-content: space-between;
        padding-right: ${({ theme }) => theme.spacing(spacingPx.outer.desktop)};
        padding-bottom: ${({ theme }) => theme.spacing(spacingPx.outer.mobile)};
        padding-left: ${({ theme }) => theme.spacing(spacingPx.outer.desktop)};
    }
`;

export const StyledSiteLinkColumn = styled('section')`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 25%;
    }
`;

export const StyledAppStoreBadgesSection = styled('section')`
    padding: ${({ theme }) => theme.spacing(spacingPx.inner.mobile)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 0;
    }
`;

export const StyledAwardBadge = styled('div')`
    display: flex;
    align-items: center;

    img {
        object-fit: contain;
    }
`;

export const StyledAppStoreBadges = styled('div')`
    width: 27.5rem;

    img {
        height: 4rem;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 24.5rem;

        img {
            height: 3.5rem;
        }
    }
`;

export const StyledAwardsContainer = styled('div')`
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
    padding: 3rem;
    padding: ${({ theme }) => theme.spacing(spacingPx.outer.mobile)};
    background-color: ${palette.primary.main};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        gap: 8rem;
        padding: 3rem 8rem;
    }
`;
