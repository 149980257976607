import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { StyledItem } from './Accordion.styled';
import { AccordionProperties } from './Accordion.types';

export function Accordion({ items, onExpand }: AccordionProperties) {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const handleClick = (index: number, itemHeading: string) => {
        if (index === expandedIndex) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
            onExpand(itemHeading);
        }
    };

    return (
        <>
            {items.map((item, index) => (
                <StyledItem
                    key={uuidv4()}
                    heading={item.heading}
                    onClick={() => handleClick(index, item.heading)}
                    open={expandedIndex === index}
                >
                    {item.content}
                </StyledItem>
            ))}
        </>
    );
}
