import getConfig from 'next/config';

import { createClientLogger } from './clientLogger';
import { createServerLogger } from './serverLogger';
import { ApplicationIdentifier } from '@tgg/common-types';

export const createAppLogger = (
    applicationIdentifier: ApplicationIdentifier,
    options: {
        NODE_ENV: string;
        APP_SERVER_LOGGING_LEVEL: string;
        APP_LOGGING_LEVEL: string;
        APP_ENVIRONMENT: string;
    },
) => {
    const {
        NODE_ENV,
        APP_SERVER_LOGGING_LEVEL,
        APP_LOGGING_LEVEL,
        APP_ENVIRONMENT,
    } = options;
    const type = typeof window !== 'undefined' ? 'CLIENT' : 'SERVER';

    const env = NODE_ENV || APP_ENVIRONMENT;
    const loggingLevel = APP_LOGGING_LEVEL || APP_SERVER_LOGGING_LEVEL;

    /* istanbul ignore next */
    const appLogger =
        type === 'CLIENT'
            ? createClientLogger(applicationIdentifier)
            : createServerLogger(applicationIdentifier, env, loggingLevel);

    return appLogger;
};

export const getAppLogger = (options: {
    applicationIdentifier: ApplicationIdentifier;
}) => {
    const { applicationIdentifier } = options;
    const {
        serverRuntimeConfig: { NODE_ENV, APP_SERVER_LOGGING_LEVEL },
        publicRuntimeConfig: { APP_LOGGING_LEVEL, APP_ENVIRONMENT },
    } = getConfig();

    return createAppLogger(applicationIdentifier, {
        NODE_ENV,
        APP_SERVER_LOGGING_LEVEL,
        APP_LOGGING_LEVEL,
        APP_ENVIRONMENT,
    });
};
