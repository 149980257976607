import {
    StyledAsterisk,
    StyledTermsWrapper,
} from './TermsAndConditions.styled';

export function TermsAndConditions() {
    return (
        <StyledTermsWrapper>
            <StyledAsterisk>* </StyledAsterisk>
            Offer available at selected sites only. See Terms &amp; Conditions
            for further details.
            <StyledAsterisk> **</StyledAsterisk>
            {` 24 hour access not currently available at all Gyms. Please see individual Gym pages for further details. ⨥Applicable terms, conditions and joining fees may apply. © ${new Date().getFullYear()} The Gym Group.`}
        </StyledTermsWrapper>
    );
}
