/* istanbul ignore file */

import { styled } from '@mui/material/styles';

interface StyledFloatingButtonProperties {
    $isVisible: boolean;
}

export const StyledFloatingButton = styled(
    'div',
)<StyledFloatingButtonProperties>`
    position: sticky;
    bottom: 2rem;
    z-index: 1;
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -2rem;
`;
