import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { ControlledCollapsible } from './ControlledCollapsible';

export const StyledItem = styled(ControlledCollapsible)`
    padding-right: ${({ theme }: { theme: Theme }) => theme.spacing(20)};
    padding-left: ${({ theme }: { theme: Theme }) => theme.spacing(20)};
    border-bottom: ${({ theme }: { theme: Theme }) =>
        `${theme.typography.pxToRem(1)} solid ${palette.common.blue}`};
`;
