import { useRouter } from 'next/router';

import { PromotionalLink } from '../../PromotionalLink';
import { StyledContainer } from './PromotionalLinksList.styled';
import { PromotionalLinksListProperties } from './PromotionalLinksList.types';
import { getAbsoluteURL, isMemberArea } from '@tgg/util';

export function PromotionalLinksList({
    items,
    isMenuOpen,
    logSideNavRedirect,
}: PromotionalLinksListProperties) {
    const router = useRouter();
    const isInMemberArea = isMemberArea(router.basePath);
    return (
        <StyledContainer>
            {items.map((promotional, index) => {
                const text = promotional.promotionalVILinkText
                    ? promotional.promotionalVILinkText
                    : promotional.promotionalLinkText;

                return (
                    <PromotionalLink
                        caseSensitive={promotional.caseSensitive}
                        key={`item${text}`}
                        promotionalLinkHref={
                            isInMemberArea
                                ? getAbsoluteURL(
                                      promotional.promotionalLinkHref,
                                  )
                                : promotional.promotionalLinkHref
                        }
                        promotionalLinkText={text}
                        chipColor={promotional.chipColor}
                        chipText={promotional.chipText}
                        divider={index !== items.length - 1}
                        tabIndex={isMenuOpen ? -1 : 0}
                        logSideNavRedirect={() =>
                            logSideNavRedirect({
                                menuParentText: 'main menu',
                                menuItemText: text,
                                redirectUrl: promotional.promotionalLinkHref,
                            })
                        }
                    />
                );
            })}
        </StyledContainer>
    );
}
