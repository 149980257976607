import { useMemo, useState } from 'react';

import { BackToTopContext } from './backToTopContext';
import { BackToTopProviderProperties } from './backToTopContext.types';

export function BackToTopProvider({ children }: BackToTopProviderProperties) {
    const [elementReference, setElementReference] =
        useState<React.RefObject<HTMLDivElement> | null>(null);

    const [additionalOffset, setAdditionalOffset] = useState<number>(0);

    const contextValue = useMemo(() => {
        return {
            elementReference,
            additionalOffset,
            setElementReference,
            setAdditionalOffset,
        };
    }, [
        elementReference,
        additionalOffset,
        setElementReference,
        setAdditionalOffset,
    ]);

    return (
        <BackToTopContext.Provider value={contextValue}>
            {children}
        </BackToTopContext.Provider>
    );
}
