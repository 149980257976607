import { StyledHeading } from './Heading.styled';
import { HeadingProperties } from './Heading.types';

/**
 * Use the `Heading` component to communicate the organisation of the content of the page.
 */
export function Heading({
    id,
    variant = 'h1',
    alignOnDesktop,
    gutterBottom = true,
    children,
    component,
    className,
    color,
    addPageContainerStyling = false,
    branchId,
    gymName,
    isLowercase = true,
    ...otherProperties
}: HeadingProperties) {
    if (!children) {
        return null;
    }

    return (
        <StyledHeading
            id={id}
            gutterBottom={gutterBottom}
            variant={variant}
            component={component}
            className={className}
            $color={color}
            $alignOnDesktop={alignOnDesktop}
            $addPageContainerStyling={addPageContainerStyling}
            $isLowercase={isLowercase}
            {...otherProperties}
        >
            {children}
        </StyledHeading>
    );
}

export default Heading;
