import { StyledParagraph } from './Paragraph.styled';
import { ParagraphProperties } from './Paragraph.types';

/**
 * Use the `Paragraph` component to show paragraphs of text.
 */
export function Paragraph({
    children,
    variant = 'body1',
    gutterBottom = true,
    level,
    ...otherParameters
}: ParagraphProperties) {
    if (!children) {
        return null;
    }

    return (
        <StyledParagraph
            {...otherParameters}
            level={level}
            gutterBottom={gutterBottom}
            variant={variant}
            component="p"
        >
            {children}
        </StyledParagraph>
    );
}

export default Paragraph;
