/* istanbul ignore file */
import { createContext } from 'react';

import { appConfig } from './appConfigDefaults';
import { ApplicationIdentifier, Nullable } from '@tgg/common-types';
import { FlagsConstants, TggLogger } from '@tgg/util';

export type AppConfigContextProperties = {
    appConfig: {
        appIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
        appLogger: Nullable<TggLogger>;
    };
};

export const AppConfigContext = createContext<AppConfigContextProperties>({
    appConfig,
});
