import getConfig from 'next/config';
import { useEffect, useState } from 'react';

import { useAppConfigContext } from '../../appConfigContext';
import { dispatchOptimizelyGaDecisionEvent } from '../helpers/dispatchOptimizelyGaEvent';
import { OptimizelyClientHookConfig } from './useOptimizelyFeClient';
import { useOptimizelyTracking } from './useOptimizelyTracking';
import { optimizelyFlagsService } from '@tgg/services';
import { defaultFlags, type TggFlagsObject, type TggLogger } from '@tgg/util';

const { publicRuntimeConfig } = getConfig();

export const getOptimizelyFlags = async (
    userId: string,
    pathname: string,
    options: {
        logger: TggLogger;
    },
) => {
    const { logger } = options;

    try {
        const { data: flags } = await optimizelyFlagsService({
            apiConfig: {
                subscriptionKey: '',
                url: publicRuntimeConfig?.APP_BASE_URL,
            },
            data: {
                overrideUserId: userId,
                pathname,
            },
        });

        return { flags, error: null };
    } catch (error_) {
        const error = error_ as Error;
        logger.error(`api/proxy/optimizely/flags failed: ${error.message}`, {
            correlationId: userId,
        });

        return { flags: defaultFlags, error };
    }
};

export const useOptimizelyProxyClient = (
    config: OptimizelyClientHookConfig,
) => {
    const [flagsLoaded, setFlagsLoaded] = useState(false);
    const {
        resolvedUserId,
        useClient,
        isUserInitialized,
        pathname,
        isRendered,
        setFlags,
        setIsClientError,
        setRender,
    } = config;

    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    useEffect(() => {
        if (
            useClient &&
            isUserInitialized &&
            appLogger &&
            !flagsLoaded &&
            !isRendered
        ) {
            getOptimizelyFlags(resolvedUserId, pathname, {
                logger: appLogger,
            })
                .then(result => {
                    const { flags, error } = result;
                    // dirty types hack
                    const resolvedFlags = flags as TggFlagsObject;
                    setFlags(resolvedFlags);

                    // GA events when no error
                    if (!error) {
                        Object.keys(resolvedFlags).forEach(flagKey => {
                            const flag =
                                resolvedFlags[flagKey as keyof TggFlagsObject];
                            // @ts-ignore
                            if (
                                flag.enabled &&
                                flag.variables?.enableDataLayer === true
                            ) {
                                dispatchOptimizelyGaDecisionEvent({
                                    flagKey,
                                    flagsUserId: resolvedUserId,
                                    pathname,
                                    ruleKey: flag.ruleKey,
                                    variationKey: flag.variationKey,
                                });
                            }
                        });
                    } else {
                        setIsClientError(true);
                    }
                })
                .finally(() => {
                    setFlagsLoaded(true);
                    setRender(true);
                });
        }
    }, [
        useClient,
        isUserInitialized,
        resolvedUserId,
        appLogger,
        isRendered,
        setFlags,
        setIsClientError,
        setRender,
        flagsLoaded,
        pathname,
    ]);

    const { trackFlagsEvent } = useOptimizelyTracking({
        pathname,
        resolvedUserId,
    });

    return { trackFlagsEvent };
};
