/* istanbul ignore file */
import { format, createLogger, transports } from 'winston';

export const loggerOptions = {
    handleExceptions: true,
    json: true,
    colorize: true,
};

const consoleFormat = format.printf(
    // eslint-disable-next-line camelcase
    ({ level, message, timestamp, correlation_id }) => {
        return JSON.stringify({
            timestamp,
            level,
            message,
            // eslint-disable-next-line camelcase
            correlation_id,
        });
    },
);

export const createWinstonLogger = (env: string, loggingLevel: string) =>
    createLogger({
        // set to silent for when NODE_ENV is undefined as unit tests were failing.
        silent: !env,
        level: loggingLevel,
        format: format.combine(
            format.label({ correlation_id: 'stage-undefined' } as any),
            format.timestamp(),
            consoleFormat,
        ),
        defaultMeta: {
            service: process.env.POD_NAME || 'default',
        },
        transports: [new transports.Console(loggerOptions)],
    });
