import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { upperFirst } from 'lodash';
import {
    GetServerSidePropsContext,
    NextApiRequest,
    NextApiResponse,
} from 'next';

import { AnyContext } from '../../cookies';
import { isEgymAppClient, isEgymAppRequest } from '../../egymAppRequests';
import {
    getOptimizelyUserId,
    getOptimizelyUserIdClientSide,
} from '../../featureFlags/cookie/featureFlagsCookie';
import { addTrailingSlash, resolvePathnameFromRequest } from '../../url';
import {
    type MetaTggTraceCorrelationParameters,
    TggTrace,
    TggTraceCorrelationParameters,
    LoggerConstants,
    InjectedTggTraceCorrelationParameters,
} from '../logger.types';
import { ApplicationIdentifier, Nullable } from '@tgg/common-types';

const clientText = 'Browser Clientside';
const serverText = 'Next.js Serverside';

const getApplicationName = (applicationIdentifier: ApplicationIdentifier) =>
    applicationIdentifier === 'member'
        ? 'Member Area'
        : upperFirst(applicationIdentifier);

export const getFullApplicationIdentifierClient = (
    applicationIdentifier: ApplicationIdentifier,
) => `TGG ${getApplicationName(applicationIdentifier)} - ${clientText}`;

export const getFullApplicationIdentifierServer = (
    applicationIdentifier: ApplicationIdentifier,
) => `TGG ${getApplicationName(applicationIdentifier)} - ${serverText}`;

export const createTraceMessage = (
    message: string,
    severityLevel: SeverityLevel,
    parameters: TggTraceCorrelationParameters,
    options: {
        applicationIdentifier: ApplicationIdentifier;
        type: 'SERVER' | 'CLIENT';
    },
): TggTrace => {
    const { type, applicationIdentifier } = options;
    const { journeyInfo, ...restParameters } = parameters;
    const traceJourneyInfo = journeyInfo
        ? `journey: ${journeyInfo.journeyName} ${journeyInfo.journeyStep ?? ''}`
        : 'journey: unknown';
    const traceMessage = `**${applicationIdentifier}** - ${type}: 
    ${message}
  
    ${traceJourneyInfo}

    meta: ${JSON.stringify(restParameters, null, 2)}
    `;

    const traceLabel =
        type === 'CLIENT'
            ? getFullApplicationIdentifierClient(applicationIdentifier)
            : getFullApplicationIdentifierServer(applicationIdentifier);

    const agent = type === 'CLIENT' ? window.navigator.userAgent : 'Next proxy';

    return {
        message: traceMessage,
        severityLevel,
        properties: {
            Application: traceLabel,
            UserAgent: agent,
        },
    };
};

export const getInjectedTggTraceCorrelationParametersSsr = (
    context: Nullable<AnyContext>,
): InjectedTggTraceCorrelationParameters => {
    if (!context?.req) {
        return {
            correlationId: LoggerConstants.UNRESOLVED,
            resolvedPath: LoggerConstants.UNRESOLVED,
            url: LoggerConstants.UNRESOLVED,
            isMobileSession: false,
        };
    }

    const { req } = context;

    const resolvedPath = resolvePathnameFromRequest(req);
    const isMobileSession = isEgymAppRequest(req);
    const { resolvedId: userId } = getOptimizelyUserId(context);

    return {
        resolvedPath,
        url: req.url || LoggerConstants.UNRESOLVED,
        correlationId: userId,
        isMobileSession,
    };
};

export const getInjectedTggTraceCorrelationParametersClient =
    (): InjectedTggTraceCorrelationParameters => {
        if (!window) {
            return {
                correlationId: LoggerConstants.UNRESOLVED,
                resolvedPath: LoggerConstants.UNRESOLVED,
                url: LoggerConstants.UNRESOLVED,
                isMobileSession: false,
            };
        }

        const isMobileSession = isEgymAppClient();
        const pathname = addTrailingSlash(window.location.pathname);

        const resolvedPath = pathname || LoggerConstants.UNRESOLVED;

        const { resolvedId: userId } = getOptimizelyUserIdClientSide();

        return {
            resolvedPath,
            url: window.location.href || LoggerConstants.UNRESOLVED,
            correlationId: userId,
            isMobileSession,
        };
    };

export const createTraceParameters = (input: {
    // Required for SSR
    context: Nullable<AnyContext>;
    parameters: MetaTggTraceCorrelationParameters;
}): TggTraceCorrelationParameters => {
    const { context, parameters } = input;
    const { correlationId } = parameters;
    const type = typeof window !== 'undefined' ? 'CLIENT' : 'SERVER';

    const { correlationId: injectedCorrelationId, ...injectedParameters } =
        type === 'SERVER'
            ? getInjectedTggTraceCorrelationParametersSsr(context)
            : getInjectedTggTraceCorrelationParametersClient();

    return {
        ...injectedParameters,
        ...parameters,
        correlationId: correlationId || injectedCorrelationId,
    };
};

export const createLoggerContext = (
    request: NextApiRequest,
    response: NextApiResponse,
): GetServerSidePropsContext => {
    return {
        req: request,
        res: response,
        query: request.query,
        resolvedUrl: request.url ?? '',
    };
};
