import { useRouter } from 'next/router';

import { NavigationItem } from '../../NavigationItem';
import {
    StyledHeadingMainMenu,
    StyledList,
    StyledHorizontalRule,
} from '../SideNavigation.styled';
import { MainNavMenuProperties } from './MainNavMenu.types';
import { isMemberArea, getAbsoluteURL, isSupportCentre } from '@tgg/util';

export function MainNavMenu({
    items,
    isMenuOpen,
    header,
    onGymListOpen,
    logSideNavRedirect,
}: MainNavMenuProperties) {
    const [findGymItem, ...restItems] = items;
    const router = useRouter();
    const isInMemberArea = isMemberArea(router.basePath);

    return (
        <>
            <StyledHeadingMainMenu variant="h3" gutterBottom={false}>
                {header}
            </StyledHeadingMainMenu>
            <StyledHorizontalRule />
            <StyledList>
                <NavigationItem
                    caseSensitive={findGymItem.caseSensitive}
                    text={findGymItem.text}
                    leftIconName={findGymItem.leftIconName}
                    onClick={onGymListOpen}
                    tabIndex={isMenuOpen ? -1 : 0}
                />
                {restItems.map(
                    ({ href, text, leftIconName, caseSensitive }, index) => (
                        <NavigationItem
                            caseSensitive={caseSensitive}
                            key={`item${text}`}
                            href={
                                href && isInMemberArea && !isSupportCentre(href)
                                    ? getAbsoluteURL(href)
                                    : href
                            }
                            {...(href && {
                                onClick: () =>
                                    logSideNavRedirect({
                                        menuParentText: header,
                                        menuItemText: text,
                                        redirectUrl: href,
                                    }),
                            })}
                            text={text}
                            leftIconName={leftIconName}
                            divider={index !== restItems.length - 1}
                            tabIndex={isMenuOpen ? -1 : 0}
                        />
                    ),
                )}
            </StyledList>
        </>
    );
}
