import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { type Logger } from 'winston';

import {
    TggLogger,
    type TggTrace,
    type TggTraceCorrelationParameters,
} from '../logger.types';
import { createTraceMessage } from '../utils/loggerUtils';
import { createWinstonLogger } from './winstonLoggerInstance';
import { ApplicationIdentifier } from '@tgg/common-types';

export type ServerLogger = ReturnType<typeof createServerLogger>;

export function getSeverityByValue(value: SeverityLevel | 'debug') {
    switch (value) {
        case 0:
            return 'verbose';
        case 1:
            return 'info';
        case 2:
            return 'warn';
        case 3:
            return 'error';
        case 4:
            return 'error';
        default:
            return 'debug';
    }
}

export const createWinstonLog = (
    trace: TggTrace,
    parameters: TggTraceCorrelationParameters,
    severityLevel: SeverityLevel | 'debug',
) => {
    return {
        level: getSeverityByValue(severityLevel),
        message: trace.message,
        correlation_id: parameters.correlationId,
        application: trace.properties.Application,
        properties: {
            ...trace.properties,
            ...parameters,
        },
    };
};

const logMessage = (options: {
    message: string;
    applicationIdentifier: ApplicationIdentifier;
    parameters: TggTraceCorrelationParameters;
    winstonLogger: Logger;
    severityLevel: SeverityLevel | 'debug';
}) => {
    const {
        applicationIdentifier,
        message,
        parameters,
        winstonLogger,
        severityLevel,
    } = options;

    const trace = createTraceMessage(
        message,
        severityLevel === 'debug' ? SeverityLevel.Information : severityLevel,
        parameters,
        {
            applicationIdentifier,
            type: 'SERVER',
        },
    );
    const winstonLog = createWinstonLog(trace, parameters, severityLevel);
    winstonLogger.log(winstonLog);
};

export const createServerLogger = (
    applicationIdentifier: ApplicationIdentifier,
    env: string,
    loggingLevel: string,
): TggLogger => {
    const winstonLogger = createWinstonLogger(env, loggingLevel);
    return {
        out(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                winstonLogger,
                severityLevel: SeverityLevel.Verbose,
            });
        },
        error(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                winstonLogger,
                severityLevel: SeverityLevel.Error,
            });
        },
        critical(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                winstonLogger,
                severityLevel: SeverityLevel.Critical,
            });
        },
        warn(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                winstonLogger,
                severityLevel: SeverityLevel.Warning,
            });
        },
        info(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                winstonLogger,
                severityLevel: SeverityLevel.Information,
            });
        },
        debug(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                winstonLogger,
                severityLevel: 'debug',
            });
        },
    };
};
