import { SeverityLevel } from '@microsoft/applicationinsights-web';

import {
    type TggLogger,
    type TggTrace,
    type TggTraceCorrelationParameters,
} from '../logger.types';
import { createTraceMessage } from '../utils/loggerUtils';
import { ApplicationIdentifier } from '@tgg/common-types';

const baseLogger = (
    trace: TggTrace,
    parameters: TggTraceCorrelationParameters,
) => {
    if (typeof window !== 'undefined' && window.appInsights) {
        const { appInsights } = window;
        appInsights.trackTrace(trace, parameters);
    }
};

export const createClientLogger = (
    applicationIdentifier: ApplicationIdentifier,
): TggLogger => ({
    out(message, parameters) {
        const trace = createTraceMessage(
            message,
            SeverityLevel.Verbose,
            parameters,
            {
                applicationIdentifier,
                type: 'CLIENT',
            },
        );
        baseLogger(trace, parameters);
    },
    info(message, parameters) {
        const trace = createTraceMessage(
            message,
            SeverityLevel.Information,
            parameters,
            {
                applicationIdentifier,
                type: 'CLIENT',
            },
        );
        baseLogger(trace, parameters);
    },
    warn(message, parameters) {
        const trace = createTraceMessage(
            message,
            SeverityLevel.Warning,
            parameters,
            {
                applicationIdentifier,
                type: 'CLIENT',
            },
        );
        baseLogger(trace, parameters);
    },
    error(message, parameters) {
        const trace = createTraceMessage(
            message,
            SeverityLevel.Error,
            parameters,
            {
                applicationIdentifier,
                type: 'CLIENT',
            },
        );
        baseLogger(trace, parameters);
    },
    critical(message, parameters) {
        const trace = createTraceMessage(
            message,
            SeverityLevel.Critical,
            parameters,
            {
                applicationIdentifier,
                type: 'CLIENT',
            },
        );
        baseLogger(trace, parameters);
    },
    debug(message, parameters) {
        this.info(message, parameters);
    },
});
