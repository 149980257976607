/* istanbul ignore file */
import { Button as MaterialButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { palette } from '../../../theme';
import { StyledButtonProperties, Style } from './Button.types';

const styleMap = (buttonStyle: Style) => {
    const { primary, secondary, success, common } = palette;

    const colors = {
        primary: {
            main: primary.main,
            active: primary.dark,
        },
        // TODO: primary-hot-pink needs to be removed from CMS as well anything relating to primary-hot-pink
        'primary-hot-pink': {
            main: secondary.main,
            active: common.blue,
        },
        'primary-alternate': {
            main: secondary.main,
            active: common.blue,
        },
        'primary-green': {
            main: success.main,
            active: success.dark,
        },
        'primary-navy': {
            main: common.blue,
            active: common.blue,
        },
        secondary: {
            main: primary.main,
            active: primary.dark,
        },
        'secondary-white': {
            main: common.white,
            active: common.blue,
        },
        'secondary-blue-white': {
            main: common.blue,
            active: common.white,
        },
        tertiary: {
            main: primary.main,
            active: primary.dark,
        },
        pino: {
            main: common.white,
            active: success.main,
        },
    };

    return colors[buttonStyle];
};

const StyledMaterialButton = styled(MaterialButton)<StyledButtonProperties>`
    padding: 0.55rem 1.4rem;
    font-size: 1.9rem;
    font-family: thegymgroupsans-Headline;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3.2rem;
`;

export const StyledPrimaryButton = styled(StyledMaterialButton, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledButtonProperties>`
    ${({ $buttonStyle }) => {
        const color = styleMap($buttonStyle);
        return `
            text-transform: lowercase;
            color: ${
                color.main === palette.secondary.main
                    ? color.active
                    : palette.common.white
            };
            background-color: ${color.main};
            &.MuiButton-root:focus {
                color: ${
                    color.main === palette.secondary.main
                        ? color.main
                        : palette.common.white
                };

                outline: 0.25rem solid ${color.active};
                outline-offset: 0.25rem;
            }

            &.MuiButton-root:hover {
                color: ${
                    color.main === palette.secondary.main
                        ? color.main
                        : palette.common.white
                };
                background-color: ${color.active};
            }

            &.MuiButton-root.Mui-disabled {
                color: ${
                    color.main === palette.secondary.main
                        ? color.active
                        : palette.common.white
                };
                background-color:
                    ${alpha(color.main, 0.5)};
                border-color: ${color.main};
            }
        `;
    }}
`;

export const SecondaryStyledButton = styled(StyledMaterialButton, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledButtonProperties>`
    ${({ theme, $buttonStyle }) => {
        const color = styleMap($buttonStyle);
        return `
            text-transform: lowercase;
            color: ${color.main};
            background-color: ${
                color.main === palette.common.white
                    ? 'transparent'
                    : palette.common.white
            };
            border: 0.1rem solid ${color.main};

            &.MuiButton-root:focus {
                outline: 0.25rem solid ${color.active};
                outline-offset: 0.25rem;
            }

            &.MuiButton-root:hover {
                color: ${
                    color.main === palette.common.white
                        ? palette.common.blue
                        : palette.common.white
                };
                background-color: ${color.main};
                border-color: ${color.main};
            }

            &.MuiButton-root.Mui-disabled {
                color: ${alpha(color.main, 0.5)};
                background-color: ${
                    color.main === palette.common.white
                        ? 'transparent'
                        : alpha(palette.common.white, 0.5)
                };
                border-color: ${color.main};
            }
        `;
    }}
`;

export const StyledTertiaryButton = styled(StyledMaterialButton, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledButtonProperties>`
    ${({ theme, $buttonStyle }) => {
        const color = styleMap($buttonStyle);

        return `
            color: ${color.main};
            text-transform: none;
            background-color: transparent;

            &:hover {
                color: ${palette.common.white};
            }

            &.MuiButton-root.Mui-disabled {
                color: ${alpha(color.main, 0.5)};
            }
        `;
    }}
`;

export const StyledSmallButton = styled(StyledMaterialButton, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledButtonProperties>`
    padding: 0.2rem 1.5rem;
    color: ${palette.common.blue};
    font-weight: 500;
    font-size: 1.2rem;
    font-family: thegymgroupsans-Bold;
    text-transform: lowercase;
    background-color: ${palette.common.white};
    border: 1px solid;
    border-radius: 3.2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.4rem;
    }

    &.MuiButton-root:focus {
        outline: 0.25rem solid ${palette.common.blue};
        outline-offset: 0.25rem;
    }

    &.MuiButton-root:hover {
        color: ${palette.common.white};
        background-color: ${palette.common.blue};
        border: 1px solid transparent;
    }
`;
