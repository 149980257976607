import { clientLogger } from '../clientLogger';
// eslint-disable-next-line import/no-self-import
import * as Analytics from './analytics';
import {
    AnalyticsElement,
    TargetedElement,
    ClickEvent,
    ScrollEvent,
    GenericEvent,
    AnalyticsDataTypes,
    EventType,
} from './analytics.types';
import { customEventHandlerMap } from './handlers';
import { debounce } from '@tgg/util';

export const trackEvent = async (event: GenericEvent) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

export const dispatchEvent = async <T>(eventType: EventType, data?: T) => {
    if (typeof window === 'undefined') {
        return;
    }

    const syntheticTarget = {
        dataset: {
            analyticsEvent: eventType,
            analyticsPayload: JSON.stringify(data || {}),
        },
    };

    const syntheticEvent = new CustomEvent('analytics-synthetic', {
        detail: {
            syntheticTarget,
        },
        bubbles: true,
    });

    window.dispatchEvent(syntheticEvent);
};

export const dispatchScrollEvent = debounce(
    async ({ target }: React.UIEvent<HTMLElement>) => {
        if (!target) return;

        const eventCopy = new CustomEvent('analytics-scroll', {
            detail: {
                target,
            },
            bubbles: true,
        });

        target.dispatchEvent(eventCopy);
    },
    500,
);

export const handleEvent = async (targetElement: Required<TargetedElement>) => {
    try {
        const {
            dataset: { analyticsEvent, analyticsPayload = '{}' },
        } = targetElement;

        const customEventHandler = customEventHandlerMap[analyticsEvent];

        const eventObject: GenericEvent = customEventHandler
            ? {
                  ...customEventHandler(analyticsPayload, targetElement),
              }
            : { event: analyticsEvent, ...JSON.parse(analyticsPayload) };

        Analytics.trackEvent({
            timestamp: Date.now(),
            ...eventObject,
        });
    } catch (error) {
        let message = 'Unknown Analytics error';

        if (error instanceof Error) {
            message = error.message;
        }

        clientLogger.warn(
            message,
            'Cannot get application name in shared library',
        );
    }
};

export const standardEventHandler = ({ target, type }: Event) => {
    if (!(target instanceof HTMLElement || target instanceof SVGElement)) {
        return;
    }

    const analyticsElement = target.closest(
        '[data-analytics-event]',
    ) as AnalyticsElement;

    if (!analyticsElement) {
        return;
    }

    const {
        dataset: { analyticsEvent },
    } = analyticsElement;

    const eventTypeMap = {
        click: ClickEvent,
        'analytics-scroll': ScrollEvent,
    } as {
        [key: string]: typeof ClickEvent | typeof ScrollEvent;
    };

    if (Object.values(eventTypeMap[type]).includes(analyticsEvent)) {
        Analytics.handleEvent(analyticsElement);
    }
};

export const initiateAnalytics = () => {
    if (typeof window === 'undefined') {
        return;
    }

    window.addEventListener('click', Analytics.standardEventHandler);

    window.addEventListener('analytics-scroll', Analytics.standardEventHandler);

    window.addEventListener(
        'analytics-synthetic',
        ({ detail: { syntheticTarget } }: Event & { detail?: any }) => {
            Analytics.handleEvent(
                syntheticTarget as unknown as TargetedElement,
            );
        },
    );
};

// We push to the data layer directly using this fn when it's not possible to use the analytics data attributes.
export const pushToDataLayer = async (data: AnalyticsDataTypes) => {
    // Ignoring this as we can't mock window.dataLayer if we need to also remove it.
    /* istanbul ignore next */
    if (typeof window === 'undefined') {
        return;
    }

    // @ts-ignore
    window.dataLayer.push({ ...data, timestamp: Date.now() });
};
