import React, { PropsWithChildren } from 'react';

import { StyledSvg } from './Svg.styled';
import { SvgProperties } from './Svg.types';

export function Svg({
    children,
    width = 24,
    height = 24,
    viewBox,
    accessibleTitle,
    ...otherProperties
}: PropsWithChildren<SvgProperties>) {
    const customViewBox = viewBox || `0 0 ${width} ${height}`;
    const accessibilityProperties = accessibleTitle
        ? { role: 'img' }
        : { 'aria-hidden': true, role: '' };

    return (
        <StyledSvg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={customViewBox}
            {...accessibilityProperties}
            {...otherProperties}
        >
            <>
                {accessibleTitle && <title>{accessibleTitle}</title>}
                {children}
            </>
        </StyledSvg>
    );
}
