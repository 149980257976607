import { useEffect, useMemo, useState } from 'react';

import { PromoCodeContext } from './promoCodeContext';
import { PromoCodeProviderProperties } from './promoCodeContext.types';

export function PromoCodeProvider({ children }: PromoCodeProviderProperties) {
    useEffect(() => {
        /* istanbul ignore else */
        if (window?.sessionStorage) {
            setInternalCode(sessionStorage.getItem('promoCode'));
        }
    }, []);

    const [code, setInternalCode] = useState<string | null>(null);

    const contextValue = useMemo(() => {
        return {
            code,
            setCode: (newCode: string) => {
                sessionStorage.setItem('promoCode', newCode);
                setInternalCode(newCode);
            },
        };
    }, [code]);

    return (
        <PromoCodeContext.Provider value={contextValue}>
            {children}
        </PromoCodeContext.Provider>
    );
}
