import { ApplicationIdentifier } from '@tgg/common-types';
import {
    getFullApplicationIdentifierClient,
    getFullApplicationIdentifierServer,
} from '@tgg/util';

export const applicationIdentifierShort: ApplicationIdentifier = 'commerce';

export const applicationIdentifier = getFullApplicationIdentifierClient(
    applicationIdentifierShort,
);
export const serverApplicationIdentifier = getFullApplicationIdentifierServer(
    applicationIdentifierShort,
);
