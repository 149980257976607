/* eslint-disable @typescript-eslint/no-floating-promises */
import { Nullable } from '@tgg/common-types';
import { EventKey, dispatchEvent } from '@tgg/services';
import { FlagsConstants } from '@tgg/util';

export const dispatchOptimizelyGaDecisionEvent = (eventData: {
    flagKey: string;
    ruleKey?: Nullable<string>;
    variationKey?: Nullable<string>;
    flagsUserId: string;
    pathname: string;
}) => {
    const { flagKey, flagsUserId, ruleKey, variationKey, pathname } = eventData;
    dispatchEvent(EventKey.FLAG_DECISION_FX, {
        'optimizely-flagKey': flagKey,
        'optimizely-ruleKey': ruleKey || FlagsConstants.UNRESOLVED,
        'optimizely-variationKey': variationKey || FlagsConstants.UNRESOLVED,
        'optimizely-userId': flagsUserId || FlagsConstants.UNRESOLVED,
        'optimizely-resolvedPath': pathname || FlagsConstants.UNRESOLVED,
    });
};

export const dispatchOptimizelyGaTrackingEvent = (eventData: {
    flagsUserId: string;
    pathname: string;
}) => {
    const { flagsUserId, pathname } = eventData;
    dispatchEvent(EventKey.FLAG_TRACKING_FX, {
        'optimizely-userId': flagsUserId || FlagsConstants.UNRESOLVED,
        'optimizely-resolvedPath': pathname || FlagsConstants.UNRESOLVED,
    });
};
