import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { dispatchEvent } from './analytics';
import { EventKey } from './analytics.types';

export const useTrackVirtualPageView = () => {
    const router = useRouter();

    useEffect(() => {
        const eventHandler = (path: any) => {
            dispatchEvent(EventKey.VIRTUAL_PAGE_VIEW, {
                virtualPageTitle: window.document.title,
                virtualPageURL: path,
            });
        };

        router.events.on('routeChangeComplete', eventHandler);

        return () => {
            router.events.off('routeChangeComplete', eventHandler);
        };
    }, [router.events]);
};
