import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import { NavigationItem } from '../../NavigationItem';
import {
    StyledSlide,
    StyledList,
    StyledSubMenuPaper,
    StyledHorizontalRule,
    BackIconContainer,
    StyledHeadingBackLink,
    StyledBackButton,
} from '../SideNavigation.styled';
import { NavItemsWithSlide } from '../SideNavigation.types';
import { SlideMemberAreaNavItemsProperties } from './SlideMemberAreaNavItems.types';

export function SlideMemberAreaNavItems({
    items,
    slideMenuState,
    onBack,
    logSideNavRedirect,
}: SlideMemberAreaNavItemsProperties) {
    const { primary } = palette;

    return (
        <>
            {items.map((memberAreaItem, index) => {
                const leftIconName = memberAreaItem.mainMenuItem
                    .leftIconName as keyof NavItemsWithSlide;
                const slideMenuOpenClosedStatus =
                    slideMenuState[`${leftIconName}`];
                const mainMenuItemText = memberAreaItem.mainMenuItem.text;

                return (
                    <StyledSlide
                        key={mainMenuItemText}
                        direction="left"
                        in={slideMenuOpenClosedStatus}
                    >
                        <StyledSubMenuPaper
                            $isHeightAuto={slideMenuOpenClosedStatus}
                        >
                            <BackIconContainer>
                                <StyledBackButton
                                    onClick={() =>
                                        onBack(leftIconName, mainMenuItemText)
                                    }
                                    aria-label="Back to the main menu"
                                >
                                    <Icon
                                        name="chevronLeft"
                                        size={12}
                                        color={primary.main}
                                    />
                                </StyledBackButton>

                                <StyledHeadingBackLink
                                    variant="h3"
                                    gutterBottom={false}
                                    onClick={() =>
                                        onBack(leftIconName, mainMenuItemText)
                                    }
                                >
                                    {mainMenuItemText}
                                </StyledHeadingBackLink>
                            </BackIconContainer>
                            <StyledHorizontalRule />
                            <StyledList>
                                {items[index].slideItems.map(
                                    (subMenuItem, subItemindex, slideItems) => {
                                        return (
                                            <NavigationItem
                                                key={`item${subMenuItem.text}`}
                                                href={subMenuItem.href}
                                                onClick={() =>
                                                    logSideNavRedirect({
                                                        menuParentText:
                                                            mainMenuItemText,
                                                        menuItemText:
                                                            subMenuItem.text,
                                                        redirectUrl:
                                                            subMenuItem.href,
                                                    })
                                                }
                                                text={subMenuItem.text}
                                                {...(subItemindex ===
                                                    slideItems.length - 1 && {
                                                    divider: false,
                                                })}
                                                itemTestId={subMenuItem.testId}
                                            />
                                        );
                                    },
                                )}
                            </StyledList>
                        </StyledSubMenuPaper>
                    </StyledSlide>
                );
            })}
            ;
        </>
    );
}
