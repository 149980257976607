import useMediaQuery from '@mui/material/useMediaQuery';
import { AxiosResponse } from 'axios';
import getConfig from 'next/config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState, MouseEvent, useEffect, useCallback } from 'react';

import { BackToTopProvider, useMenuItemsContext } from '../../contexts';
import { theme } from '../../theme';
import { BackToTopButton } from '../BackToTopButton';
import { DownloadApp } from '../DownloadApp';
import { Footer, StyledAwardBadge } from '../Footer';
import { GymTourOverlay } from '../GymTourOverlay';
import { Header } from '../Header';
import { SideNavigation } from '../SideNavigation';
import { TermsAndConditions } from '../TermsAndConditions';
import { StyledMain } from './Layout.styled';
import { LayoutProperties } from './Layout.types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    EventKey,
    dispatchEvent,
    CtaButtonClickPayload,
    EventType,
    SideNavMenuStatePayload,
    ClickEvent,
} from '@tgg/services';
import { relativeUrlWithBasePath, truncateText, isMemberArea } from '@tgg/util';

const {
    publicRuntimeConfig: { APP_BASE_PATH, JOIN_NOW_HREF },
} = getConfig();

const logMenuChange = async <T,>(eventType: EventType, data: T) => {
    dispatchEvent<T>(eventType, data);
};

/**
 * Use the `Layout` component to add header, side navigation and footer on the page
 */
export function Layout({
    children,
    joinNowHref,
    loggedInUserName = '',
    headerSnackBar,
    appBannerVisible = false,
    isMobileSession = false,
    showFooter = true,
    hideFloatingButton,
}: LayoutProperties) {
    const router = useRouter();
    const [menuOpen, setMenuOpen] = useState(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const [accountHref, setAccountHref] = useState('');

    const onCtaClickFunction = (
        event: MouseEvent<HTMLElement>,
        ctaPosition: string,
        // eslint-disable-next-line unicorn/consistent-function-scoping
    ) => {
        dispatchEvent<CtaButtonClickPayload>(EventKey.CTA_BUTTON_CLICK, {
            ctaName: event.currentTarget.textContent as string,
            ctaPosition,
        });
    };

    const { menuItems } = useMenuItemsContext();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [userNameFromApiCall, setUserNameFromApiCall] = useState('');
    const [returnToAfterLogout, setReturnToAfterLogout] = useState('');
    const isInMemberArea = isMemberArea(router.basePath);
    const logoutUrl = isInMemberArea
        ? `${APP_BASE_PATH}/api/auth/logout/`
        : `${APP_BASE_PATH}/api/auth/logout/?returnTo=${returnToAfterLogout}`;
    const joinNowHrefValue = joinNowHref ?? JOIN_NOW_HREF;
    const sideNavStickyButtonHref = isUserLoggedIn
        ? logoutUrl
        : joinNowHrefValue;

    const sideNavStickyButtonText = isUserLoggedIn ? 'Logout' : 'Join Now';
    const sideNavHamBurgerHeadingIcon = isInMemberArea ? 'account' : 'menu';
    const sideNavCommonItemsHeading = isInMemberArea
        ? 'The Gym'
        : 'Joining The Gym';

    const characterLimit = isDesktop ? 10 : 12;
    const userName = loggedInUserName || userNameFromApiCall;
    const sideNavHamBurgerHeadingText = userName
        ? truncateText(`Hi, ${userName}`, characterLimit)
        : 'Menu';

    const showBorderBottom = isInMemberArea && router.asPath === '/';

    useEffect(() => {
        const accountWithBasePath = isInMemberArea
            ? relativeUrlWithBasePath('/')
            : relativeUrlWithBasePath('/member-area/');

        setAccountHref(accountWithBasePath);
    }, [isInMemberArea]);

    useEffect(() => {
        const returnTo = !isInMemberArea ? window.location.pathname : '';
        setReturnToAfterLogout(returnTo);
    }, [isInMemberArea]);

    useEffect(() => {
        const checkLoggedIn = () =>
            axiosInstance.get(`${APP_BASE_PATH}/api/auth/me`);
        checkLoggedIn()
            .then((response: AxiosResponse<any>) => {
                setIsUserLoggedIn(true);
                setUserNameFromApiCall(response.data.given_name);
            })
            .catch(() => {
                setIsUserLoggedIn(false);
            });
    }, []);

    const onMenuChange = useCallback(
        (isOpen: boolean) => {
            setMenuOpen(isOpen);

            const eventType = isOpen ? EventKey.MENU_OPEN : EventKey.MENU_CLOSE;
            const menuContext = isInMemberArea ? 'member area' : 'ecommerce';

            dispatchEvent<SideNavMenuStatePayload>(eventType, {
                menuContext,
            });
        },
        [isInMemberArea],
    );
    const onMenuOpen = useCallback(() => {
        onMenuChange(true);
    }, [onMenuChange]);
    const onMenuClose = useCallback(() => {
        onMenuChange(false);
    }, [onMenuChange]);

    const isHome = router.pathname === '/';

    if (isMobileSession) {
        return (
            <StyledMain
                $hasSnackBar={!!headerSnackBar}
                $appBannerVisible={appBannerVisible}
                $isMobileSession={isMobileSession}
            >
                {children}
            </StyledMain>
        );
    }
    return (
        <>
            <Header
                onMenuIconClick={onMenuOpen}
                headerSnackBar={headerSnackBar}
                appBannerVisible={appBannerVisible}
                accountHref={accountHref}
                shouldShowJoinNowCta={isHome}
                showBorderBottom={showBorderBottom}
            />
            <SideNavigation
                menuItems={menuItems}
                open={menuOpen}
                stickyButton={{
                    href: sideNavStickyButtonHref,
                    text: sideNavStickyButtonText,
                }}
                hamBurgerHeading={{
                    text: sideNavHamBurgerHeadingText,
                    icon: sideNavHamBurgerHeadingIcon,
                }}
                commonItemsHeadingText={sideNavCommonItemsHeading}
                onClose={onMenuClose}
                onCtaClick={(event: MouseEvent<HTMLElement>) =>
                    onCtaClickFunction(event, 'side navigation')
                }
                logMenuChange={logMenuChange}
                isInMemberArea={isInMemberArea}
            />
            <GymTourOverlay setMenuOpen={setMenuOpen} />
            <BackToTopProvider>
                <StyledMain
                    $hasSnackBar={!!headerSnackBar}
                    $appBannerVisible={appBannerVisible}
                >
                    {children}
                </StyledMain>

                {showFooter && (
                    <Footer
                        appStoreBadges={
                            <DownloadApp
                                isShowTextContent={false}
                                appStoreLinkProps={{
                                    'data-analytics-event':
                                        ClickEvent.FOOTER_LINK,
                                    'data-analytics-payload': JSON.stringify({
                                        footer_section: 'get the gym group app',
                                        link_text: 'App Store app link',
                                        link_destination:
                                            'https://apps.apple.com/gb/app/the-gym/id1444707310',
                                    }),
                                }}
                                googlePlayLinkProps={{
                                    'data-analytics-event':
                                        ClickEvent.FOOTER_LINK,
                                    'data-analytics-payload': JSON.stringify({
                                        footer_section: 'get the gym group app',
                                        link_text: 'Play Store app link',
                                        link_destination:
                                            'https://play.google.com/store/apps/details?id=com.netpulse.mobile.thegymgroup&amp;hl=en_GB&amp;gl=US',
                                    }),
                                }}
                            />
                        }
                        awardsBadges={[
                            <StyledAwardBadge key="sunday-times_2024">
                                <Image
                                    src={relativeUrlWithBasePath(
                                        '/ywnqh/images/sunday-times_2024.png',
                                    )}
                                    alt="Sundays times award"
                                    width={186}
                                    height={80}
                                    quality={100}
                                />
                            </StyledAwardBadge>,
                            <StyledAwardBadge key="carbon-neutral">
                                <Image
                                    src={relativeUrlWithBasePath(
                                        '/ywnqh/images/carbon-neutral.png',
                                    )}
                                    alt="Carbon Neutral award"
                                    width={80}
                                    height={80}
                                    quality={100}
                                />
                            </StyledAwardBadge>,
                            <StyledAwardBadge key="invest-in-people-gold">
                                <Image
                                    src={relativeUrlWithBasePath(
                                        '/ywnqh/images/invest-in-people-gold.png',
                                    )}
                                    alt="Investors in People Gold award"
                                    width={195}
                                    height={36}
                                    quality={100}
                                />
                            </StyledAwardBadge>,
                            <StyledAwardBadge key="glassdoor-2022">
                                <Image
                                    src={relativeUrlWithBasePath(
                                        '/ywnqh/images/glassdoor-2022.png',
                                    )}
                                    alt="Glassdoor best places to work '22 award"
                                    width={150}
                                    height={55}
                                    quality={100}
                                />
                            </StyledAwardBadge>,
                        ]}
                    />
                )}
                {!hideFloatingButton && !isInMemberArea && <BackToTopButton />}
            </BackToTopProvider>
            {showFooter && <TermsAndConditions />}
        </>
    );
}
