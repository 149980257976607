import { CookieSerializeOptions } from 'cookie';
import { CookieAttributes } from 'js-cookie';

import {
    getCookie,
    deleteCookie,
    setCookie,
    updateCookie,
} from './cookies.browser.helpers';
import {
    getCookieSsr,
    deleteCookieSsr,
    updateCookieSsr,
    setCookieSsr,
    expireCookieSsr,
} from './cookies.ssr.helpers';
import {
    AVAILABLE_COOKIES,
    ApiForCookie,
    ApiForCookieSsr,
    AvailableCookieBuckets,
} from './cookies.types';
import { composeFullCookieName } from './utils/cookies.utils';

export const createApiForCookie = <C>(
    cookieBucket: AvailableCookieBuckets,
    cookieName: AVAILABLE_COOKIES,
    defaultCookieParameters: CookieAttributes,
    defaultEnvironment: string,
): ApiForCookie<C> => ({
    getCookie: () =>
        getCookie<C>({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
        }),
    deleteCookie: (cookieParameters, environment) =>
        deleteCookie({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            cookieParameters: cookieParameters ?? defaultCookieParameters,
            environment: environment ?? defaultEnvironment,
        }),
    setCookie: (value, cookieParameters, environment) =>
        setCookie<C>({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            value,
            cookieParameters: cookieParameters ?? defaultCookieParameters,
            environment: environment ?? defaultEnvironment,
        }),
    updateCookie: (value, cookieParameters, environment) =>
        updateCookie<C>({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            value,
            cookieParameters: cookieParameters ?? defaultCookieParameters,
            environment: environment ?? defaultEnvironment,
        }),
});

export const createApiForCookieSsr = <C>(
    cookieBucket: AvailableCookieBuckets,
    cookieName: AVAILABLE_COOKIES,
    defaultValue: Partial<C>,
    defaultCookieParameters: CookieAttributes | CookieSerializeOptions,
    defaultEnvironment: string,
): ApiForCookieSsr<C> => ({
    getCookieSsr: context =>
        getCookieSsr<C>({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            context,
        }),
    expireCookieSsr: (context, cookieParameters, environment) =>
        expireCookieSsr<C>({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            cookieParameters: cookieParameters ?? defaultCookieParameters,
            context,
            environment: environment ?? defaultEnvironment,
            emptyValue: defaultValue,
        }),
    deleteCookieSsr: context =>
        deleteCookieSsr({
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            context,
        }),
    updateCookieSsr: (context, value, cookieParameters, environment) =>
        updateCookieSsr<C>({
            context,
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            value,
            cookieParameters: cookieParameters ?? defaultCookieParameters,
            environment: environment ?? defaultEnvironment,
        }),
    setCookieSsr: (context, value, cookieParameters, environment) =>
        setCookieSsr<C>({
            context,
            cookieKey: composeFullCookieName(cookieName, cookieBucket),
            value,
            cookieParameters: cookieParameters ?? defaultCookieParameters,
            environment: environment ?? defaultEnvironment,
        }),
});

export const createCookieApi = <C>(
    cookieBucket: AvailableCookieBuckets,
    cookieName: AVAILABLE_COOKIES,
    defaultValue: Partial<C>,
    cookieParameters: CookieAttributes,
    environment: string,
) => ({
    browser: {
        ...createApiForCookie<C>(
            cookieBucket,
            cookieName,
            cookieParameters,
            environment,
        ),
    },
    ssr: {
        ...createApiForCookieSsr<C>(
            cookieBucket,
            cookieName,
            defaultValue,
            cookieParameters,
            environment,
        ),
    },
});
